<template>
  <component :is="component" :question="question" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Questions Preview components
import ObjectivePreview from '../question/preview/ObjectivePreview.vue'
import SliderPreview from '../question/preview/SliderPreview.vue'
import OpenEndedPreview from '../question/preview/OpenEndedPreview.vue'
import FillInTheBlankPreview from '../question/preview/FillInTheBlankPreview.vue'
import TrueFalsePreview from '../question/preview/TrueFalsePreview.vue'
import ConnecTheDotsPreview from '../question/preview/ConnecTheDotsPreview.vue'
import SortingPreview from '../question/preview/SortingPreview.vue'
import MatrixPreview from '../question/preview/MatrixPreview.vue'
import EssayPreview from '../question/preview/EssayPreview.vue'

const props = defineProps(['question'])

const components = [
  {
    type: 'MULTIPLE_CHOICE',
    component: ObjectivePreview
  },
  {
    type: 'OBJECTIVE',
    component: ObjectivePreview
  },
  {
    type: 'OPEN_ENDED',
    component: OpenEndedPreview
  },
  {
    type: 'ESSAY',
    component: EssayPreview
  },
  {
    type: 'SLIDER',
    component: SliderPreview
  },
  {
    type: 'FILL_IN_THE_BLANK',
    component: FillInTheBlankPreview
  },
  {
    type: 'TRUE_FALSE',
    component: TrueFalsePreview
  },
  {
    type: 'CONNECT_THE_DOTS',
    component: ConnecTheDotsPreview
  },
  {
    type: 'SORTING',
    component: SortingPreview
  },
  {
    type: 'MATRIX',
    component: MatrixPreview
  }
]

const component = computed(() => {
  return (
    components.find((component) => component.type === props.question.type)?.component ??
    ObjectivePreview
  )
})
</script>
