<template>
    <div class="w-[50%] pt-2">
        <v-slider class="mt-5" v-model="answerSlider" show-ticks="always" thumb-label="always" color="blue"
            :ticks="zeroToMax()" tick-size="4" :min="params.min" :max="params.max" step="1">
        </v-slider>
    </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { ref } from 'vue';
import { toRefs } from 'vue';

const props = defineProps({
    params: {
        type: Object,
        required: true
    },
    answer: {
        type: Number,
        required: true,
        default: () => 0
    }
})

const { params, answer } = toRefs(props)

const emit = defineEmits(['answer'])

const answerSlider = ref(answer.value)

const zeroToMax = () => {
    let ticks = Array(params.value.max - params.value.min).fill('')
    ticks[0] = String(params.value.min)
    ticks[ticks.length - 1] = String(params.value.max)
    return ticks
}

let timerId: any = null

watch(answerSlider, () => {
    clearTimeout(timerId)
    timerId = setTimeout(() => emit('answer', [answerSlider.value]), 3000)
})
</script>