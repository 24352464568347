<template>
    <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_364_11083)">
            <path
                d="M35.3647 0.805551L28.0414 6.60658L14.8291 17.062H14.8243L11.7183 13.8905C11.0772 13.2349 9.61914 12.9471 8.77853 13.2857L1.66417 16.0411C0.405624 16.5322 -0.781685 17.3378 0.664457 18.8207L13.1003 31.4896C13.7415 32.1451 14.8552 32.1935 15.4465 31.4896L25.6336 19.3505C25.6336 19.3505 25.6241 19.3601 25.6217 19.365L38.5016 4.01571C39.2188 3.11096 39.1451 1.80222 38.3401 0.984565C37.5375 0.15481 36.2529 0.0919132 35.3647 0.80797V0.805551Z"
                fill="#1C86E3" />
            <path
                d="M14.4208 11.5125C17.5458 11.5125 20.0772 8.93378 20.0772 5.75023C20.0772 2.56668 17.5435 0 14.4208 0C11.2982 0 8.77637 2.57877 8.77637 5.75023C8.77637 8.92168 11.3077 11.5125 14.4208 11.5125Z"
                fill="#1C86E3" />
        </g>
        <defs>
            <clipPath id="clip0_364_11083">
                <rect width="39" height="32" fill="white" />
            </clipPath>
        </defs>
</svg></template>