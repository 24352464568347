<template>
  <h1 class="text-[16px] font-bold mb-[12px]">Calendário</h1>
  <CalendarDateComponent class="overflow-x-auto" @selectedDate="selectDate" />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { watch } from 'vue';
import { ref } from 'vue';
import CalendarDateComponent from '../calendar/CalendarDateComponent.vue';

const date = ref(new Date())

const emit = defineEmits([
  'send:date'
])

const selectDate = (e: any) => {
  date.value = e
}

onMounted(() => {
  emit('send:date', date.value)
})

watch(date, (newDate) => {
  emit('send:date', newDate)
})
</script>
