import api from '@/config/axios'
import { auth } from '@/config/firebase'
import meilisearchApi from '@/config/meilisearch'
import { getIdToken, type User } from 'firebase/auth'
import type { AxiosRequestConfig } from 'axios'

export const search = async (
  indexUid: string,
  perPage: number = 10,
  query: string,
  page: number,
  filter?: any,
  sort?: any
) => {
  const response = await meilisearchApi.post(`/indexes/${indexUid}/search`, {
    hitsPerPage: perPage,
    q: query,
    page,
    filter,
    sort
  })

  if (response.status !== 200) throw new Error('Error searching in Meilisearch')

  const pagination = {
    total: response.data.totalHits,
    perPage: response.data.hitsPerPage,
    page: response.data.page,
    totalPages: response.data.totalPages
  }

  return {
    hits: response.data.hits,
    pagination
  }
}

export const generateToken = async () => {
  localStorage.removeItem('meilisearchToken')

  const token = await getIdToken(auth?.currentUser as User, true)

  const result = await api.post(
    '/generateMeilisearchTokenStudent',
    {
      organization_id: localStorage.getItem('activeOrganization')
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    } as AxiosRequestConfig
  )

  if (result.status !== 201) throw new Error('Error generating token')

  localStorage.setItem('meilisearchToken', result.data.data.token)

  return result.data
}
