import { db } from '@/config/firebase'
import { doc, getDoc } from 'firebase/firestore'

export const getProviderById = async (id: string) => {
  if (!id?.length) return null

  const ref = doc(db, 'providers', id)
  const snapshot = await getDoc(ref)

  if (!snapshot?.exists()) {
    return null
  }

  return snapshot.data() || null
}