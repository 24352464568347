<template>
    <v-container>
        <v-row>
            <v-col v-for="i of 4" :key="i" :cols="mobile ? 12 : 6">
                <v-card
                    style="background: #E8F3FC"
                    elevation="0"
                    class="bg-transparent !border-2 !border-[#A4CFF4]'"
                    :class="{ 'mx-5': mobile }"
                    rounded="xl">
                    <v-card-item class="min-h-[100px] animate-pulse">
                        <div class="flex items-center gap-8">
                            <v-avatar size="35" color="#d6d6d6"></v-avatar>
                            <div class="flex gap-4 flex-col w-full">
                                <div class="bg-[#d6d6d6] w-full h-3 rounded"></div>
                                <div class="bg-[#d6d6d6] w-full h-3 rounded"></div>
                            </div>
                        </div>
                    </v-card-item>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup lang="ts">
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay()

</script>