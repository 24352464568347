<template>
  <div>
    <template v-if="provider?.id === ProviderEnum.LT">
      <ListMaterialComponent
        :materials="ltData.studentsMaterials"
        :title="t('Material de apoio')"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useLocale } from 'vuetify'
import { onMounted, ref } from 'vue'
import ListMaterialComponent from '../../components/support-material/ListMaterialComponent.vue'
import ProviderEnum from '../../enums/provider.enum'
import { useLoadingStore } from '@/stores/loading'
import { getProviderById } from '@/services/provider.service'

const loadingStore = useLoadingStore()

const provider = ref()

onMounted(async () => {
  try {
    loadingStore.startLoading()
    provider.value = await getProviderById(ProviderEnum.LT)
  } catch (e) {
    console.error(e)
  } finally {
    loadingStore.stopLoading()
  }
})

const { t } = useLocale()

const ltData = ref({
  studentsMaterials: [
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%203%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=c5e180a6-2457-41d9-9f06-c6c0321a3472',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 3º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_3_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_3_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%203%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=1c875290-b2de-4d52-979b-8bdafa403b3c',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 3º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_3_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_3_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%203%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=76167725-d5bc-4e7f-ad57-7b6f64ce6324',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 3º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_3_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_3_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%203%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=8b4dfdfc-1a10-4d4c-b151-eccd3413d544',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 3º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_3_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_3_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%204%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=9ee9441f-a92b-4f0a-bd91-7763af3fcf9a',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 4º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_4_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_4_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%204%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=51e54cea-81e2-43a2-ba63-c450aacdcb91',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 4º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_4_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_4_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%204%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=e47bac60-9849-48c0-99b1-99aa5d51e37f',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 4º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_4_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_4_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%204%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=569eb9ca-b575-435e-937d-59e9bd3f8ca9',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 4º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_4_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_4_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%205%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=d080a716-18ff-46f2-b23a-f394fa00074a',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 5º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%205%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=62dda44b-69d1-4089-b9b0-ef139e4cb974',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 5º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%205%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=f2892938-08dc-4b78-a880-e18d18392d7b',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 5º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%205%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=927197e2-8b21-4022-8cc5-2e6144ed2907',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 5º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%206%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=219e8add-f15c-4241-b58e-30581b118344',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 6º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_6_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_6_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%206%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=d7579551-c351-4fa3-816d-54022420fac9',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 6º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_6_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_6_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%206%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=1ea09573-6cea-400c-8499-a0ce44fa26bd',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 6º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_6_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_6_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%206%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=c499e480-a3b9-4015-a06e-8afd1c9a7a23',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 6º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_6_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_6_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%207%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=7287881c-27e6-4659-9e8e-f2f30f1aef36',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 7º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_7_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_7_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%207%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=b1ae4ec1-51dc-4d4c-abae-b24211ecdb93',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 7º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_7_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_7_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%207%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=a591fae4-2eaa-48f0-9fa1-2e1f7883309b',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 7º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_7_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_7_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%207%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=cf077696-891e-4f4e-8f80-93bd4f3e227d',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 7º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_7_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_7_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%208%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=e0731620-476d-4705-a13e-91e60b6370cb',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 8º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_8_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_8_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%208%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=e337ca79-0b5c-465d-981e-a45820a4365d',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 8º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_8_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_8_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%208%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=3df0929c-6404-4a3a-afcb-275f837fb63a',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 8º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_8_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_8_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%208%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=ac83f406-3a15-420f-8452-c6851d82707f',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 8º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_8_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_8_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20-%20LA%20-%20Vol%20I.jpg?alt=media&token=38359aa0-f4e9-4e0c-bb4a-744157fe2b90',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA -9º ANO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_9_Ano_LA_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_9_Ano_LA_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20-%20LA%20-%20Vol%20II.jpg?alt=media&token=01b64461-64a0-4f15-af7a-77cb37cc4b52',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 9º ANO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_9_Ano_LA_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_9_Ano_LA_Vol_II_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20Encarte%20-%20Vol%20I.jpg?alt=media&token=cc470110-c7d4-4066-beeb-ceae50c41762',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 9º ANO - ENCARTE VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_9_Ano_Encarte_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_9_Ano_Encarte_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20Encarte%20-%20Vol%20II.jpg?alt=media&token=f643f6d4-3dcc-4163-9466-55dd4d271557',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 9º ANO - ENCARTE VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_9_Ano_Encarte_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_9_Ano_Encarte_Vol_II_Inverted_colors.pdf'
    },

    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20-%20Produ%C3%A7%C3%A3o%20de%20texto%20-%20Vol%20I.jpg?alt=media&token=f6544e2c-091e-4106-b8ad-a9d5aed43036',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 9º ANO - PRODUÇÃO DE TEXTO - VOLUME I',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_Produção_de_texto_Vol_I.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_Produção_de_texto_Vol_I_Inverted_colors.pdf'
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/pruvo-prod-v2.appspot.com/o/materialsPdfs%2FnewMaterial%2Fthumbs%2FLP%20-%209%20Ano%20-%20Produ%C3%A7%C3%A3o%20de%20texto%20-%20Vol%20II.jpg?alt=media&token=23cf6135-8135-493b-a1b9-6478fe645816',
      type: 'pdf',
      title: 'LÍNGUA PORTUGUESA - 9º ANO - PRODUÇÃO DE TEXTO - VOLUME II',
      curricularUnit: 'SABE BRASIL',
      link: 'newMaterial/LP_5_Ano_Produção_de_texto_Vol_II.pdf',
      linkAccessibily: 'newMaterial/LP_5_Ano_Produção_de_texto_Vol_II_Inverted_colors.pdf'
    }
  ]
})
</script>
