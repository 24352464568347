<template>
    <div class="relative">
        <div class="flex px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2 ">
            <div :class="{
                'absolute left-6 bottom-10 px-1 bg-white': !mobile,
                'relative bottom-6 px-1 bg-white': mobile
            }">
                <span class="text-[#808080] text-xs font-normal not-italic">Resposta</span>
            </div>
            <span class="text-base font-normal not-italic text-[#333333]" style="letter-spacing: 0.15px;"
                v-html="answerComputed"></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();
const props = defineProps(['question'])

const answerComputed = computed(() => {
    return props.question.answers[0]
})
</script>