<template>
    <p class="border-2 border-blue-200 rounded-xl pa-3 break-words" :style="fontBase">
        <template v-for="(wording, index) in wordings" :key="index">
            <input :value="(collect(answersSelected) as any).where('index', wording).first()?.answer"
                v-if="typeof wording == 'number'" type="text" @change="applyAnswer($event, wording)"
                onkeypress="this.style.width = ((this.value.length + 1) * 8) + 'px';"
                class="text-[#808080] mx-2 px-2 custom_border_bottom outline-none" />
            <span v-if="typeof wording == 'string'" class="p-0.5" v-html="wording"></span>
        </template>
    </p>
</template>
<script setup lang="ts">
import collect from 'collect.js';
import { computed, ref, toRefs } from 'vue';
import { useFontZoomStore } from '@/stores/font'

const fontBase = computed(() => useFontZoomStore().getFontBase)

const props = defineProps(
    {
        wordings: {
            type: Array,
            required: true,
        },
        answers: {
            type: Array,
            required: true,
            default: () => []
        }
    }
)
const { wordings, answers } = toRefs(props)
const answersSelected = ref(answers.value)
const emit = defineEmits(['answer'])

const applyAnswer = (answer: Event, index: number) => {
    const indexExistsInAnswer = collect(answersSelected.value).contains('index', index)

    if (!indexExistsInAnswer) {
        answersSelected.value.push({
            index,
            answer: (answer.target as HTMLInputElement).value
        })
    } else {
        answersSelected.value.map((wording: any) => {
            if (wording.index === index) {
                wording.answer = (answer.target as HTMLInputElement).value
            }
            return wording
        })
    }

    emit('answer', answersSelected.value)
}
</script>

<style>
.custom_border_bottom {
    border-bottom: 1px solid #000;
    min-width: 70px;
}
</style>