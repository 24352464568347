<template>
    <div class="mb-[20px] cursor-pointer" v-for="alternative, index of alternatives" :key="alternative.id">
        <div :class="answerSelected.includes(alternative.id) ? 'border-[#1C86E3]' : 'border-[#E8F3FC] alternative'"
            class="flex items-center border-2 rounded-xl" @click="selectAnswer(alternative.id)">
            <div :class="answerSelected.includes(alternative.id) ? 'bg-[#1C86E3] rounded-s-2xl text-white' : 'blue-border-right'"
                class="position-relative h-[72px] p-[16px] flex justify-center items-center font-bold order-wording">
                <span :style="fontBase">
                    {{ numberToLetter(index) }}
                </span>
            </div>
            <div class="px-[20px] py-[16px]">
                <QuestionMathJax :text="alternative?.wording" :style="fontBase" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';
import { useFontZoomStore } from '@/stores/font'
import QuestionMathJax from './QuestionMathJax.vue';
import { numberToLetter } from '@/utils/helpers'

const fontBase = computed(() => useFontZoomStore().getFontBase)

const props = defineProps({
    alternatives: {
        type: Object,
        required: true
    },
    answers: {
        type: Array<any>,
        required: true,
        default: () => []
    }
})

const { alternatives, answers } = toRefs(props)

const answerSelected = ref(answers.value)

const emit = defineEmits(['answer'])

const selectAnswer = (alternativeId: any) => {
    answerSelected.value = [
        alternativeId
    ]

    emit('answer', answerSelected.value)
}
</script>

<style scoped>
.blue-border-right::after {
    content: "";
    height: 55px;
    width: 2px;
    background: #E8F3FC;
    position: absolute;
    right: 0;
}

.alternative:hover .order-wording {
    background-color: #D2E7F9;
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
}
</style>