import { generateToken } from '@/services/meilisearch.service'
import axios, { type CreateAxiosDefaults } from 'axios'
import JwtUtils from '@/utils/jwt.utils'
import collect from 'collect.js'

const meilisearchUrl = import.meta.env.VITE_MEILISEARCH_URL as string

const meilisearchApi = axios.create({
  baseURL: meilisearchUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
} as CreateAxiosDefaults)

const getOrganizationIdFromFilter = (filter: string) => {
  let organizationId = ''
  const parts = filter?.split(" AND ");

  parts?.forEach(part => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [key, operator, value] = part.split(' ');
    if (key === 'organization_id') organizationId = value
  });

  return organizationId;
}

const isTokenValid = (token: string) => {
  const organizationId = localStorage.getItem('activeOrganization') as string

  if (!token || !JwtUtils.isJwt(token)) return false
  const tokenDecoded = JwtUtils.decodeJwt(token)

  if (tokenDecoded?.exp < Date.now() / 1000) return false

  return collect(tokenDecoded?.searchRules)
    .values()
    .map((rule: any) => getOrganizationIdFromFilter(rule?.filter))
    .all()
    .includes(organizationId)
}

meilisearchApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('meilisearchToken')
  if (token && isTokenValid(token)) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  }
  const { data } = await generateToken()
  config.headers.Authorization = `Bearer ${data.token}`
  return config
})

meilisearchApi.interceptors.response.use(undefined, async (interceptor: any) => {
  if (interceptor.response.status === 403 && localStorage.getItem('meilisearchToken')) {
    await generateToken()

    location.reload()

    return interceptor
  }
})

export default meilisearchApi
