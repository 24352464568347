export enum ApplicationStatus {
  NOT_STARTED = 'NOT_STARTED',
  CANCELLED = 'CANCELLED',
  ENDED = 'ENDED',
  IN_PROGRESS = 'IN_PROGRESS',
  EXPIRED = 'EXPIRED',
  PUBLISHED = 'PUBLISHED',
  RUNNING = 'RUNNING',
  CORRECTED = 'CORRECTED',
  WAITING = 'WAITING'
}
