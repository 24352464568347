<template>
    <v-chip v-if="application?.status === ApplicationStatus.NOT_STARTED" rounded class="!bg-[#A4CFF4] mb-[4px]">
        <span class="text-black text-xs font-bold">
            {{
                application?.starts_at?.seconds ?
                getBrazilianDate(moment.unix(application?.starts_at?.seconds)).format('DD/MM/YYYY HH:mm') : 'Sem data final'
            }}
            até
            {{
                application?.ends_at?.seconds ?
                getBrazilianDate(moment.unix(application?.ends_at?.seconds)).format('DD/MM/YYYY HH:mm') : 'Sem data final'
            }}
        </span>
    </v-chip>
    <v-chip v-else-if="userApplication?.status" rounded class="!bg-[#A4CFF4] mb-[4px]">
        <span class="text-black text-xs font-bold">
            {{ getApplicationStatusName(userApplication?.status as any) }}
        </span>
    </v-chip>
</template>

<script setup lang="ts">
import type { Application } from '@/models/application.model';
import { computed, type PropType } from 'vue';
import { ApplicationStatus } from '@/enums/application-status.enum';
import { getBrazilianDate } from '@/utils/date';
import moment from 'moment';
import { getApplicationStatusName } from '@/services/application.service';
import { useDocument } from "vuefire"
import { db } from '@/config/firebase';
import { doc } from 'firebase/firestore';

const props = defineProps({
    application: {
        type: Object as PropType<Application>,
        required: true
    }
})

const userApplicationSource = computed(() => {
    if (!props.application?.id || !props.application?.user_id) return
    return doc(db, 'users', props.application.user_id, 'applications', props.application.id)
})

const userApplication = useDocument(userApplicationSource);
</script>