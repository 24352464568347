import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    showModal: false,
    description: '',
    icon: 1,
    buttonText: 'Ok, entendi!'
  }),
  actions: {
    sendModal(description: string, icon: number, buttonText: string) {
      this.showModal = true
      this.description = description
      this.icon = icon
      this.buttonText = buttonText
    },
    closeModal() {
      this.showModal = false
      this.description = ''
      this.icon = 1
      this.buttonText = 'Ok, entendi!'
    }
  }
})
