<template>
  <div class="p-4 bg-[#fff] rounded-xl">
    <div class="flex flex-col">
      <div v-for="(material, index) in materials" :key="index">
        <v-card class="item" elevation="0" @click="openMaterial(material.link)">
          <div class="flex flex-col p-5">
            <div class="flex flex-col">
              <div class="flex space-x-5">
                <MaterialChipcomponent
                  v-if="material.curricularComponent"
                  :label="material.curricularComponent"
                  background-color="#E8F3FC"
                  text-color="#1C86E3"
                />
                <MaterialChipcomponent
                  v-if="material.type"
                  :label="material.type"
                  background-color="#E8F3FC"
                  text-color="#1C86E3"
                />
                <MaterialChipcomponent
                  v-if="material.content"
                  :label="material.content"
                  background-color="#FEE9E7"
                  text-color="#931706"
                />
                <MaterialChipcomponent
                  v-if="material.schoolYear"
                  :label="material.schoolYear"
                  background-color="#E8F3FC"
                  text-color="#1C86E3"
                />
              </div>
              <span
                class="text-[#333] text-base mt-1 font-medium sm:overflow-hidden sm:text-ellipsis sm:whitespace-nowrap"
              >
                {{ material.title }}
              </span>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>

  <BaseDialogComponent
    v-if="dialogVisible"
    v-model="dialogVisible"
    :height="displayHeight"
    :save-button="false"
    :width="'100%'"
    class="!p-0"
    title="Material de apoio"
    @close-dialog="dialogVisible = false"
  >
    <div class="iframe-container">
      <iframe :src="linkHtml" class="iframe"></iframe>
    </div>
  </BaseDialogComponent>
</template>

<script lang="ts" setup>
import { useMaterialDialog } from '@/composables/useMaterialDialog'
import BaseDialogComponent from '@/components/dialog/BaseDialogComponent.vue'
import { ref } from 'vue'
import MaterialChipcomponent from '@/components/chips/MaterialChipcomponent.vue'

const props = defineProps<{
  materials: Array<{
    curricularComponent?: string
    type?: string
    content?: string
    schoolYear?: string
    title: string
    link: string
  }>
}>()
const { dialogVisible, displayHeight } = useMaterialDialog()
const linkHtml = ref('')
const openMaterial = (link: string) => {
  dialogVisible.value = true
  linkHtml.value = link
}
</script>

<style scoped>
.item {
  border-radius: 8px;
  border: 1px solid var(--preto-90, #e6e6e6);
  background: var(--preto-99, #fcfcfc);
  box-shadow: 0 1px 2px rgba(27, 36, 44, 0.12);
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .iframe-container {
    height: 600px;
  }
}

@media (max-width: 480px) {
  .iframe-container {
    height: 400px;
  }
}
</style>
