<template>
  <v-row class="mb-10" no-gutters>
    <v-col cols="12" md="3">
      <v-sheet class="px-[24px] py-[20px] rounded-xl mb-6">
        <ApplicationsCalendar @send:date="selectedDate = $event" />
        <div class="pt-[24px] text-lg font-bold">
          <div class="pt-[24px] text-lg font-bold">
            {{ days[selectedDate.getDay() as keyof typeof days] }},
            {{ months[selectedDate.getMonth() as keyof typeof months] }}
            {{ selectedDate.getDate() }}
          </div>
        </div>
        <div
          v-for="applicationFromDay of applicationsFromDay"
          :key="applicationFromDay.id"
          class="mt-[12px]"
        >
          <div
            class="flex justify-between items-center p-[12px] font-medium"
            style="border: 2px solid #e8f3fc; border-radius: 12px"
          >
            <div>
              {{ applicationFromDay.name }}
            </div>
            <div>
              <v-tooltip location="top" text="Informações">
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="!border-none"
                    color="blue"
                    elevation="0"
                    icon="mdi-information-outline"
                    size="large"
                    title=""
                    variant="outlined"
                    v-bind="props"
                    @click="startCard(applicationFromDay)"
                  ></v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-sheet>
      <template v-if="getProviderId === ProviderEnum.LT">
        <v-sheet
          class="w-full h-[64px] rounded-xl flex items-center justify-between pt-4 pb-4 pl-6 pr-3 mt-4 mb-4 transition duration-400 ease-in-out hover:scale-102 cursor-pointer hover:shadow-[0_10px_30px_rgba(0,0,0,0.1)]"
          @click="router.push('/support-material')"
        >
          <button
            outlined
            class="flex items-center w-full justify-between text-black font-rubik font-medium text-base normal-case border-none mr-auto"
          >
            <div class="flex items-center">
              <v-icon class="mr-2 text-[#1C86E3] text-3xl">mdi-folder-outline</v-icon>
              <span class="text-left">Material de apoio</span>
            </div>
            <v-icon class="ml-2 text-[#1C86E3] text-2xl">mdi-chevron-right</v-icon>
          </button>
        </v-sheet>
      </template>
    </v-col>
    <v-col cols="12" md="9">
      <v-row>
        <v-col cols="12">
          <v-sheet
            :class="{
              'py-[20px] ml-[20px] rounded-xl': !mobile,
              'py-[20px] rounded-xl': mobile
            }"
          >
            <v-main :class="{ 'mx-10': !mobile }">
              <div
                :class="{
                  'flex items-center mb-[24px]': !mobile,
                  'flex items-center ml-5': mobile
                }"
              >
                <ExamsOpenIcon class="mr-[15px]" />
                <span class="mr-[12px] font-bold">Avaliações em aberto</span>
                <v-chip color="blue" rounded size="small" variant="flat"
                  >{{ applicationsNotStarted?.length }}
                </v-chip>
              </div>
              <template v-if="isLoadingOpenApplications">
                <ApplicationSkeletonLoader />
              </template>
              <template v-else-if="applicationsNotStarted?.length">
                <ApplicationsList
                  :applications="applicationsNotStarted"
                  :is_not_started="true"
                  @resume="resumeApplication($event)"
                  @start="startCard($event)"
                />
              </template>
              <template v-else>
                <div class="flex justify-center items-center pt-[24px] flex-column">
                  <div class="mb-[24px] font-bold">Você não tem avaliações em aberto!</div>
                  <img :src="svgPath.student_no_exams" alt="Estudante sem avaliações encontradas" />
                </div>
              </template>
            </v-main>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet
            :class="{
              'py-[20px] ml-[20px] rounded-xl': !mobile,
              'py-[20px] rounded-xl': mobile
            }"
          >
            <v-main :class="{ 'mx-10': !mobile }">
              <div
                :class="{
                  'flex items-center': !mobile,
                  'flex items-center ml-5': mobile
                }"
              >
                <ExamsFinishedIcon class="mr-[15px]" />
                <span class="mr-[12px] font-bold">Avaliações concluídas</span>
                <v-chip color="blue" rounded size="small" variant="flat"
                  >{{ paginationData.total }}
                </v-chip>
              </div>
              <div
                :class="{
                  'mt-[24px]': !mobile,
                  'mt-[24px] ml-5 mr-5': mobile
                }"
              >
                <v-text-field
                  base-color="blue"
                  density="compact"
                  hide-details
                  label="Pesquisar"
                  rounded
                  variant="outlined"
                  @keyup="handleSearchApplicationsEnded($event)"
                >
                  <template #prepend-inner>
                    <v-icon class="mr-4" color="blue">mdi-magnify</v-icon>
                  </template>
                </v-text-field>
              </div>
              <template v-if="applicationsEndedLoading">
                <ApplicationSkeletonLoader />
              </template>
              <template v-else-if="applicationsFinished?.length">
                <ApplicationsList :applications="applicationsFinished" :is_not_started="false" />
                <div
                  :class="{
                    'flex justify-end items-center mt-[24px]': !mobile,
                    'flex justify-end items-center mr-5': mobile
                  }"
                >
                  <v-pagination
                    v-if="!applicationsEndedLoading"
                    v-model="paginationData.page"
                    :length="paginationData.totalPages"
                    :total-visible="mobile ? 1 : 10"
                    color="primary"
                    variant="outlined"
                    @update:model-value="handleSearchApplicationsEnded"
                  >
                  </v-pagination>
                </div>
              </template>
              <template v-else>
                <div class="flex justify-center items-center pt-[24px] flex-col">
                  <div class="mb-[24px] font-bold">Não encontramos nenhuma avaliação</div>
                  <img
                    :src="svgPath.student_no_exams_done"
                    alt="Estudante sem avaliações concluídas"
                  />
                </div>
              </template>
            </v-main>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-dialog v-model="dialog" width="420">
      <SelectedApplication
        :selectedApplication="selectedApplication"
        @closeDialog="dialog = false"
        @start="startApplication($event)"
      />
    </v-dialog>
  </v-row>
</template>

<script lang="ts" setup>
import ApplicationsList from './ApplicationsList.vue'
import ApplicationsCalendar from './ApplicationsCalendar.vue'
import ExamsOpenIcon from '../icons/ExamsOpenIcon.vue'
import ExamsFinishedIcon from '../icons/ExamsFinishedIcon.vue'
import { onMounted, readonly, ref, watch } from 'vue'
import { days, months } from '@/utils/date'
import {
  getApplicationNameById,
  getApplicationsFromDay,
  getUserApplications,
  saveFormDataIntoUserApplication,
  startUserApplication
} from '@/services/application.service'
import type { Application } from '@/models/application.model'
import SelectedApplication from '../card/SelectedApplication.vue'
import { useRouter } from 'vue-router'
import { search } from '@/services/meilisearch.service'
import Helpers from '@/utils/helpers'
import { useDisplay } from 'vuetify'
import moment from 'moment'
import { useLoadingStore } from '@/stores/loading'
import ApplicationSkeletonLoader from '@/components/dashboard/ApplicationSkeletonLoader.vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import ProviderEnum from '@/enums/provider.enum'

const helpers = new Helpers()

const { mobile } = useDisplay()
const loadingStore = useLoadingStore()

const applicationsNotStarted = ref([] as Application[])
const applicationsFinished = ref([] as Application[])
const applicationsFromDay = ref([] as Application[])
const paginationData = ref({} as any)
const applicationsEndedLoading = ref(false)
const isLoadingOpenApplications = ref(false)
const isLoadingApplicationsFromDay = ref(false)
const getProviderId = ref('')

onMounted(async () => {
  try {
    loadingStore.startLoading()

    await Promise.all([
      handleOpenApplications(),
      handleApplicationsFromDay(),
      handleSearchApplicationsEnded(),
      getProvider()
    ])
  } finally {
    loadingStore.stopLoading()
  }
})

async function getProvider() {
  const organization = await getActiveOrganizationData()
  getProviderId.value = organization?.provider_id
}

async function handleOpenApplications() {
  try {
    isLoadingOpenApplications.value = true
    applicationsNotStarted.value = await getUserApplications()
  } finally {
    isLoadingOpenApplications.value = false
  }
}

async function handleApplicationsFromDay() {
  try {
    isLoadingApplicationsFromDay.value = true
    applicationsFromDay.value = await getApplicationsFromDay(
      moment(selectedDate.value).format('YYYY-MM-DD')
    )
  } finally {
    isLoadingApplicationsFromDay.value = false
  }
}

const handleSearchApplicationsEnded = async (query?: any) => {
  applicationsEndedLoading.value = true
  applicationsFinished.value = []
  await helpers.debounce(async () => {
    await getApplicationsEnded(query)
    applicationsEndedLoading.value = false
  }, 1)
}

const getApplicationsEnded = async (query?: any) => {
  const { hits, pagination } = await search(
    'users_applications',
    4,
    query?.target?.value,
    paginationData.value.page,
    ['status IN [ENDED, EXPIRED, CANCELLED, PUBLISHED, CORRECTED, WAITING]'],
    ['ended_at._seconds:desc']
  )
  applicationsFinished.value = await Promise.all(
    hits.map(async (hit: any) => {
      hit.name = hit.application_id
        ? await getApplicationNameById(hit.application_id)
        : 'Aplicação sem nome'
      return hit
    })
  )
  paginationData.value = pagination
}

const selectedDate = ref(new Date())

watch(selectedDate, async (newDate) => {
  applicationsFromDay.value = await getApplicationsFromDay(moment(newDate).format('YYYY-MM-DD'))
})

const selectedApplication = ref({} as Application)
const dialog = ref(false)

const startCard = (application: Application) => {
  if (application?.is_google_forms) {
    window.open(application?.google_forms?.responderUri, '_blank')
    return
  }

  dialog.value = true
  selectedApplication.value = application
}

const router = useRouter()

const startApplication = async (application: Application) => {
  try {
    loadingStore.startLoading()
    const userApplicationId = await saveFormDataIntoUserApplication(application)
    await startUserApplication(userApplicationId)
    await router.push(`/resolution/${userApplicationId}`)
  } finally {
    loadingStore.stopLoading()
  }
}

const resumeApplication = async (application: Application) => {
  await router.push(`/resolution/${application.id}`)
}

watch(dialog, (newDialog) => {
  if (!newDialog) {
    selectedApplication.value = {} as Application
  }
})

const svgPath = ref(
  readonly({
    student_no_exams: '/assets/svg/student_no_exams.svg',
    student_no_exams_done: '/assets/svg/student_confused.svg'
  })
)
</script>
