<template>
    <MainLayout :showLoggedHeader="false">
        <template #body>
            <ResolutionComponent />
        </template>
    </MainLayout>
</template>

<script setup lang="ts">
import MainLayout from '@/components/layout/MainLayout.vue';
import ResolutionComponent from '@/components/resolution/ResolutionComponent.vue';
import LogGravityEnum from '@/enums/log-gravity.enum';
import LogService from '@/services/log.service';
import { provide } from 'vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const isShowResolution = ref(true);
const route = useRoute()
const router = useRouter()
const logService = new LogService(route.params.applicationId as string)

provide('isShowResolution', isShowResolution)

document.addEventListener('contextmenu', (e) => {
    if (route.name !== 'resolution') return

    return e.preventDefault()
});

async function listenKeydown(e: any) {
    if (route.name !== 'resolution') return

    if (e.ctrlKey && e.key !== 'Control') {
        await logService.log(`Tecla pressionada: Control + ${e.key}`, LogGravityEnum.HIGH)
        return
    } else if (e.altKey && e.key !== 'Alt') {
        await logService.log(`Tecla pressionada: Alt + ${e.key}`, LogGravityEnum.HIGH)
        return
    } else if (e.shiftKey && e.key !== 'Shift') {
        await logService.log(`Tecla pressionada: Shift + ${e.key}`, LogGravityEnum.LOW)
        return
    } else if (e.metaKey && e.key !== 'Meta') {
        await logService.log(`Tecla pressionada: Meta + ${e.key}`, LogGravityEnum.LOW)
        return
    } else if (!['Control', 'Alt', 'Shift', 'Meta'].includes(e.key)) {
        await logService.log(`Tecla pressionada: ${e.key}`, LogGravityEnum.LOW)
        return
    }
}

document.addEventListener('keydown', listenKeydown);

window.onblur = async function () {
    if (route.name !== 'resolution') return

    if ((screen as any).isExtended) {
        await logService.log('Usuário trocou de aba/janela (Atenção: usuário tem dois monitores)', LogGravityEnum.SEVERE)
    } else {
        await logService.log('Usuário trocou de aba/janela', LogGravityEnum.SEVERE)
    }
    router.push({
        name: 'dashboard', query: {
            exit: 1
        }
    })
}

window.onfocus = async function () {
    if (route.name !== 'resolution') return

    await logService.log('Usuário voltou para a avaliação', LogGravityEnum.LOW)
}
</script>