<template>
    <div class="relative">
        <div class="flex px-[10px] py-[10px] border-[#D2E7F9] items-start self-stretch rounded-[20px] border-2 ">
            <div :class="{
                'absolute left-6 bottom-10 px-1 bg-white': !mobile,
                'relative bottom-6 px-1 bg-white': mobile
            }">
                <span class="text-[#808080] text-xs font-normal not-italic font-['Rubik']">Resposta</span>
            </div>
            <span
                class="text-base font-normal not-italic text-[#333333]"
                style="letter-spacing: 0.15px;"
                v-html="answer" />
        </div>
    </div>
</template>

<script setup lang="ts">
import collect from 'collect.js';
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const props = defineProps(['question'])

const { mobile } = useDisplay();

const answer = computed(() => {
    const answer: string[] = []
    collect(props.question.wordings).each((wording: any, index: any) => {
        if (typeof wording === 'string') {
            answer.push(wording)
        } else {
            const newAnswer: string = props.question.answers.find((answer: any) => answer.index === index).answer;
            answer.push(`<strong>${newAnswer || 'NÃO PREENCHIDO'}</strong>`)
        }
    })
    return answer
        .toString()
        .split(',')
        .join(' ')
})

</script>
