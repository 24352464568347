import { defineStore } from 'pinia';

const textXs = 'font-size: 0.75rem !important;line-height: 1rem !important;' // 12 px 16 px
const textSm = 'font-size: 0.875rem !important;line-height: 1.25rem !important;' // 14 px 20 px
const textBase = 'font-size: 1rem !important;line-height: 1.5rem !important;' // 16 px 24 px
const textLg = 'font-size: 1.125rem !important;line-height: 1.75rem !important;' // 18 px 28 px
const textXl = 'font-size: 1.25rem !important;line-height: 1.75rem !important;' // 20 px 28 px
const text2Xl = 'font-size: 1.5rem !important;line-height: 2rem !important;' // 24 px 32 px
const text3Xl = 'font-size: 1.875rem !important;line-height: 2.25rem !important;' // 30 px 36 px

export const useFontZoomStore = defineStore('fontZoomStore', {
    state: () => ({
        fontZoom: 0,
        min: 0,
        max: 2,
    }),
    actions: {
        setFontZoom(type: 'add' | 'sub') {
            if (type === 'add') {
                this.fontZoom += 1;
            } else {
                this.fontZoom -= 1;
            }
        }
    },
    getters: {
        getFontZoom(): number {
            return this.fontZoom;
        },
        getFontXs(): string {
            return [textXs, textSm, textBase][this.fontZoom]
        },
        getFontSm(): string {
            return [textSm, textBase, textLg][this.fontZoom]
        },
        getFontBase(): string {
            return [textBase, textLg, textXl][this.fontZoom]
        },
        getFontLg(): string {
            return [textLg, textXl, text2Xl][this.fontZoom]
        },
        getFontXl(): string {
            return [textXl, text2Xl, text3Xl][this.fontZoom]
        }
    }
})