<template>
  <main>
    <MainLayout>
      <template #body>
        <DashboardList />
      </template>
    </MainLayout>
  </main>
</template>

<script setup lang="ts">
import DashboardList from '@/components/dashboard/DashboardList.vue'
import MainLayout from '@/components/layout/MainLayout.vue';
import { useModalStore } from '@/stores/modal';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const modalStore = useModalStore()

onMounted(() => {
  if (route.query.exit === '1') {
    modalStore.sendModal('Você saiu da avaliação por trocar de aba/janela.', 2, 'Ok, entendi!')
    window.history.pushState({}, "", window.location.href.split('?')[0]);
  }
})
</script>