import {
  deleteObject,
  type FirebaseStorage,
  getBlob,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes
} from 'firebase/storage'

export default class StorageService {
  storage!: FirebaseStorage

  constructor() {
    this.storage = getStorage()
  }

  public async uploadFile(name: string, file: File | Blob) {
    return await uploadBytes(ref(this.storage, name), file)
  }

  public async downloadFile(name: string) {
    const fileRef = ref(this.storage, name)
    return await getBlob(fileRef)
  }

  public async deleteFile(name: string) {
    const fileRef = ref(this.storage, name)
    return await deleteObject(fileRef)
  }

  public async getFileUrl(name: string) {
    const fileRef = ref(this.storage, name)
    return await getDownloadURL(fileRef)
  }
}
