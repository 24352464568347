<template>
    <v-textarea v-limit-characters="params.max" variant="outlined" v-model="answerOpenEnded" class="w-full">
        <template #label>
            <span class="!text-[#808080]">Resposta</span>
        </template>
    </v-textarea>
    <div class="flex justify-between text-xs">
        <div>
            <span><span class="font-bold">{{params.min}}-{{ params.max }}</span> caracteres</span>
        </div>
        <div>
            <span class="mr-[24px]">Caracteres:<span class="font-bold">{{ answerOpenEnded.length }}</span></span>
            <span>Palavras:<span class="font-bold">{{ answerOpenEnded.split(' ').length }}</span></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { ref, toRefs } from 'vue';

const props = defineProps({
    answer: {
        type: String,
        required: true,
        default: ''
    },
    params: {
        type: Object,
        required: false,
        default: () => ({min: 0, max: 1000})
    },
})

const { answer, params } = toRefs(props)

const emit = defineEmits(['answer'])

const answerOpenEnded = ref(answer.value)

const selectAnswer = (answerOpenEnded: string) => {
    emit('answer', [answerOpenEnded])
}

let timerId: any = null

watch(answerOpenEnded, (value) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => selectAnswer(value), 3000)
})
</script>
