<template>
    <div class="flex" :style="fontBase">
        <draggable @end="changeOrder" tag="ul" class="w-full" :list="alternativesMounted" handle=".handle"
            item-key="element.id">
            <template #item="{ element, index }">
                <div class="my-5 flex min-w-full">
                    <svg class="handle my-auto cursor-grabbing mr-[16px]" width="36" height="36" viewBox="0 0 36 36"
                        v-if="!mobile" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="34" height="34" rx="17" fill="#FAFDFE" />
                        <path
                            d="M13.2931 13.7068C13.1056 13.5193 13.0003 13.265 13.0003 12.9998C13.0003 12.7346 13.1056 12.4803 13.2931 12.2928L17.2931 8.29279C17.4806 8.10532 17.7349 8 18.0001 8C18.2652 8 18.5196 8.10532 18.7071 8.29279L22.7071 12.2928C22.8026 12.385 22.8788 12.4954 22.9312 12.6174C22.9836 12.7394 23.0112 12.8706 23.0123 13.0034C23.0135 13.1362 22.9882 13.2678 22.9379 13.3907C22.8876 13.5136 22.8134 13.6253 22.7195 13.7192C22.6256 13.8131 22.5139 13.8873 22.391 13.9376C22.2681 13.9879 22.1365 14.0132 22.0037 14.012C21.8709 14.0109 21.7397 13.9833 21.6177 13.9309C21.4957 13.8785 21.3853 13.8023 21.2931 13.7068L18.0001 10.4138L14.7071 13.7068C14.5196 13.8943 14.2652 13.9996 14.0001 13.9996C13.7349 13.9996 13.4806 13.8943 13.2931 13.7068ZM13.2931 23.7068L17.2931 27.7068C17.4806 27.8943 17.7349 27.9996 18.0001 27.9996C18.2652 27.9996 18.5196 27.8943 18.7071 27.7068L22.7071 23.7068C22.8892 23.5182 22.99 23.2656 22.9878 23.0034C22.9855 22.7412 22.8803 22.4904 22.6949 22.305C22.5095 22.1196 22.2587 22.0144 21.9965 22.0121C21.7343 22.0098 21.4817 22.1106 21.2931 22.2928L18.0001 25.5858L14.7071 22.2928C14.6148 22.1973 14.5045 22.1211 14.3825 22.0687C14.2605 22.0163 14.1293 21.9887 13.9965 21.9875C13.8637 21.9864 13.732 22.0117 13.6091 22.062C13.4862 22.1122 13.3746 22.1865 13.2807 22.2804C13.1868 22.3743 13.1125 22.4859 13.0623 22.6088C13.012 22.7317 12.9867 22.8634 12.9878 22.9962C12.989 23.129 13.0166 23.2602 13.069 23.3822C13.1214 23.5042 13.1976 23.6145 13.2931 23.7068Z"
                            fill="#1C86E3" />
                        <rect x="1" y="1" width="34" height="34" rx="17" stroke="#1C86E3" stroke-width="2" />
                    </svg>
                    <div class="flex items-center justify-between border-2 border-[#E8F3FC] rounded-xl w-full">
                        <div class="flex items-center">
                            <div v-if="!mobile"
                                class="position-relative h-[72px] blue-border-right p-[16px] flex justify-center items-center font-bold">
                                {{ numberToLetter(index) }}
                            </div>
                            <div v-else
                                class="position-relative blue-border-right flex justify-center items-center font-bold h-[72px]">
                                <svg class="handle my-auto cursor-grabbing " width="20" height="20" viewBox="0 0 36 36"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1" y="1" width="34" height="34" rx="17" fill="#FAFDFE" />
                                    <path
                                        d="M13.2931 13.7068C13.1056 13.5193 13.0003 13.265 13.0003 12.9998C13.0003 12.7346 13.1056 12.4803 13.2931 12.2928L17.2931 8.29279C17.4806 8.10532 17.7349 8 18.0001 8C18.2652 8 18.5196 8.10532 18.7071 8.29279L22.7071 12.2928C22.8026 12.385 22.8788 12.4954 22.9312 12.6174C22.9836 12.7394 23.0112 12.8706 23.0123 13.0034C23.0135 13.1362 22.9882 13.2678 22.9379 13.3907C22.8876 13.5136 22.8134 13.6253 22.7195 13.7192C22.6256 13.8131 22.5139 13.8873 22.391 13.9376C22.2681 13.9879 22.1365 14.0132 22.0037 14.012C21.8709 14.0109 21.7397 13.9833 21.6177 13.9309C21.4957 13.8785 21.3853 13.8023 21.2931 13.7068L18.0001 10.4138L14.7071 13.7068C14.5196 13.8943 14.2652 13.9996 14.0001 13.9996C13.7349 13.9996 13.4806 13.8943 13.2931 13.7068ZM13.2931 23.7068L17.2931 27.7068C17.4806 27.8943 17.7349 27.9996 18.0001 27.9996C18.2652 27.9996 18.5196 27.8943 18.7071 27.7068L22.7071 23.7068C22.8892 23.5182 22.99 23.2656 22.9878 23.0034C22.9855 22.7412 22.8803 22.4904 22.6949 22.305C22.5095 22.1196 22.2587 22.0144 21.9965 22.0121C21.7343 22.0098 21.4817 22.1106 21.2931 22.2928L18.0001 25.5858L14.7071 22.2928C14.6148 22.1973 14.5045 22.1211 14.3825 22.0687C14.2605 22.0163 14.1293 21.9887 13.9965 21.9875C13.8637 21.9864 13.732 22.0117 13.6091 22.062C13.4862 22.1122 13.3746 22.1865 13.2807 22.2804C13.1868 22.3743 13.1125 22.4859 13.0623 22.6088C13.012 22.7317 12.9867 22.8634 12.9878 22.9962C12.989 23.129 13.0166 23.2602 13.069 23.3822C13.1214 23.5042 13.1976 23.6145 13.2931 23.7068Z"
                                        fill="#1C86E3" />
                                </svg>
                            </div>
                            <div class="px-[20px] py-[16px]">
                                <QuestionMathJax :text="element?.wording" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <div class="flex my-12 mx-5 flex-col justify-between">
            <span class="border-8 rounded-lg w-10"
                :class="hasChanged.has(alternative.id) ? 'border-[#1C86E3]' : 'border-[#E6E6E6]'"
                v-for="alternative in alternativesMounted" :key="alternative.id"></span>
        </div>
        <div class="flex my-5 w-full flex-col justify-between">
            <div v-for="wording, index in wordings" :key="wording.index">
                <div class="flex border-2 border-[#E8F3FC] rounded-xl w-full">
                    <div class="flex items-center">
                        <div v-if="!mobile"
                            class="position-relative h-[72px] blue-border-right p-[16px] flex justify-center items-center font-bold">
                            {{ numberToLetter(index) }}
                        </div>
                        <div :class="{
                            'px-[20px]': !mobile,
                            'h-[72px] px-[20px] flex justify-center items-center': mobile
                        }">
                            <QuestionMathJax :text="wording?.wording" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { toRefs } from 'vue';
import draggable from 'vuedraggable'
import { useFontZoomStore } from '@/stores/font'
import collect from 'collect.js';
import { useDisplay } from 'vuetify';
import QuestionMathJax from './QuestionMathJax.vue';
import { numberToLetter } from '@/utils/helpers'

const fontBase = computed(() => useFontZoomStore().getFontBase)

const props = defineProps({
    alternatives: {
        type: Array<any>,
        required: true
    },
    answers: {
        type: Array<any>,
        default: () => []
    },
    wordings: {
        type: Array<any>
    }
})

const { answers, wordings } = toRefs(props)

const { mobile } = useDisplay();

const emit = defineEmits(['answer'])

const alternativesMounted = ref(
    collect(props.alternatives).shuffle().toArray() as any
)

const hasChanged = ref<Set<any>>(new Set())

onMounted(() => {
    if (answers.value.length) {
        alternativesMounted.value = collect(answers.value)
            .sortBy('answer')
            .map(answer => {
                if (answer.is_changed) {
                    hasChanged.value.add(answer.alternative_id)
                }
                return {
                    id: answer.alternative_id,
                    wording: props.alternatives.find((alternative: any) => alternative.id === answer.alternative_id).wording
                }
            })
            .toArray() as any
    }
})

const changeOrder = (event: any) => {
    alternativesMounted.value.forEach((alternative: any, index: number) => {
        if (index === event.newIndex) {
            hasChanged.value.add(alternative.id)
        }
    })

    const newAnswers = alternativesMounted.value.map((alternative: any, index: number) => {
        return {
            alternative_id: alternative.id,
            index: index + 1,
            is_changed: hasChanged.value.has(alternative.id)
        }
    })

    emit('answer', newAnswers)
}
</script>

<style scoped>
.blue-border-right::after {
    content: "";
    height: 55px;
    width: 2px;
    background: #E8F3FC;
    position: absolute;
    right: 0;
}
</style>