<template>
  <v-container>
    <v-row v-if="!mobile">
      <template v-if="applications.length">
        <v-col v-for="application in displayedApplicationsList" :key="application!.title" cols="6">
          <v-card
            :class="
              ![
                ApplicationStatus.ENDED,
                ApplicationStatus.PUBLISHED,
                ApplicationStatus.CANCELLED,
                ApplicationStatus.EXPIRED,
                ApplicationStatus.CORRECTED,
                ApplicationStatus.WAITING
              ].includes(application.status)
                ? '!bg-[#E8F3FC]'
                : 'bg-transparent !border-2 !border-[#A4CFF4]'
            "
            elevation="0"
            rounded="xl"
            style="background: #e8f3fc"
          >
            <v-card-item class="m-[24px] !p-0">
              <div class="flex justify-between items-center">
                <ApplicationStatusChip :application="application" />
                <img
                  v-if="application!.is_google_forms"
                  height="31"
                  src="/assets/google/google-forms-icon.webp"
                  width="31"
                />
              </div>
              <v-card-title class="mb-[4px]">{{ application!.name }}</v-card-title>
              <v-card-subtitle
                v-if="
                  ![
                    ApplicationStatus.ENDED,
                    ApplicationStatus.PUBLISHED,
                    ApplicationStatus.CORRECTED,
                    ApplicationStatus.WAITING
                  ].includes(application.status)
                "
              >
                Realizada em:
                {{
                  application.ended_at?.seconds
                    ? getBrazilianDate(moment.unix(application.ended_at!.seconds)).format(
                        'DD/MM/YYYY'
                      )
                    : 'Não definido'
                }}
              </v-card-subtitle>
              <v-card-actions class="!p-0">
                <ApplicationButtonAction :application="application" />
              </v-card-actions>
            </v-card-item>
          </v-card>
        </v-col>
        <v-col v-if="is_not_started && applications.length > 4">
          <template v-if="hasMoreApplications">
            <BaseButtonLoudMore
              @click="loadMoreApplications"
              customText="Ver mais avaliações em aberto"
              :icon="LoadMoreStatusEnum.MORE"
            />
          </template>
          <template v-else>
            <BaseButtonLoudMore
              @click="loadLessApplications"
              customText="Ver menos"
              fill="#fff"
              :icon="LoadMoreStatusEnum.NO_MORE"
            />
          </template>
        </v-col>
      </template>
    </v-row>
    <v-col v-else>
      <template v-if="applications.length >= 1">
        <v-col v-for="application in applications" :key="application.title">
          <v-card
            :class="
              ![
                ApplicationStatus.ENDED,
                ApplicationStatus.PUBLISHED,
                ApplicationStatus.CANCELLED,
                ApplicationStatus.EXPIRED,
                ApplicationStatus.CORRECTED,
                ApplicationStatus.WAITING
              ].includes(application.status)
                ? '!bg-[#E8F3FC]'
                : 'bg-transparent !border-2 !border-[#A4CFF4]'
            "
            elevation="0"
            rounded="xl"
            style="background: #e8f3fc"
          >
            <v-card-item class="m-[24px] !p-0">
              <ApplicationStatusChip :application="application" />
              <v-card-title class="mb-[4px]">{{ application.name }}</v-card-title>
              <v-card-subtitle
                v-if="
                  [
                    ApplicationStatus.ENDED,
                    ApplicationStatus.PUBLISHED,
                    ApplicationStatus.CORRECTED,
                    ApplicationStatus.WAITING
                  ].includes(application.status)
                "
              >
                Realizada em:
                {{
                  application.ended_at?._seconds
                    ? getBrazilianDate(moment.unix(application.ended_at?._seconds)).format(
                        'DD/MM/YYYY'
                      )
                    : 'Não definido'
                }}
              </v-card-subtitle>
              <v-card-actions class="!p-0">
                <ApplicationButtonAction :application="application" />
              </v-card-actions>
            </v-card-item>
          </v-card>
        </v-col>
      </template>
    </v-col>
  </v-container>
</template>
<script lang="ts" setup>
import type { Application } from '@/models/application.model'
import { computed, provide, ref, toRefs, watch } from 'vue'
import moment from 'moment'
import { ApplicationStatus } from '@/enums/application-status.enum'
import { useRouter } from 'vue-router'
import { getBrazilianDate } from '@/utils/date'
import ApplicationStatusChip from './ApplicationStatusChip.vue'
import ApplicationButtonAction from './ApplicationButtonAction.vue'
import { useDisplay } from 'vuetify'
import BaseButtonLoudMore from '@/components/base/BaseButtonLoudMore.vue'
import { LoadMoreStatusEnum } from '@/enums/load-more-status.enum'

const router = useRouter()

const props: {
  applications?: Application[]
  is_not_started?: Boolean
} = defineProps(['applications', 'is_not_started'])

const { applications } = toRefs(props)

const { mobile } = useDisplay()

const displayedApplications = ref(3)
const hasMoreApplications = ref(true)

const displayedApplicationsList = computed(() => {
  if (props.is_not_started) {
    return applications!.value!.slice(0, displayedApplications.value)
  } else {
    return applications!.value
  }
})

const loadMoreApplications = () => {
  if (displayedApplications.value + 4 < applications!.value!.length) {
    displayedApplications.value += 4
  } else {
    displayedApplications.value = applications!.value!.length
    hasMoreApplications.value = false
  }
}

const loadLessApplications = () => {
  displayedApplications.value = 3
  hasMoreApplications!.value = true
}

watch(displayedApplications, (newValue) => {
  if (newValue >= applications!.value!.length!) {
    hasMoreApplications.value = false
  } else {
    hasMoreApplications.value = true
  }
})

const emit = defineEmits(['start', 'resume', 'paginate'])

const start = (application: Application) => {
  emit('start', application)
}
provide('start', start)

const resume = (application: Application) => {
  emit('resume', application)
}
provide('resume', resume)

const goToFeedback = (applicationId: string) => {
  router.push(`/feedback/${applicationId}`)
}
provide('goToFeedback', goToFeedback)
</script>

<style scoped>
.v-container {
  padding-right: 0;
  padding-left: 0;
}
</style>
