<template>
    <div>
        <div :style="fontXs">
            Progresso
        </div>
        <div class="font-bold text-[#1C86E3]" :style="fontSm">
            {{ percentage }}%
        </div>
    </div>
    <div>
        <div :style="fontXs">
            Questões respondidas
        </div>
        <div class="text-right" :style="fontSm">
            <span class="font-bold text-[#1C86E3]" >{{ questionsAnsweredCount }}/</span>{{
                questionsCount }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { useFontZoomStore } from '@/stores/font';
import { computed } from 'vue';

const fontXs = computed(() => useFontZoomStore().getFontXs)
const fontSm = computed(() => useFontZoomStore().getFontSm)

defineProps(['percentage', 'questionsCount', 'questionsAnsweredCount'])

</script>