import axios from 'axios'

const baseApi = import.meta.env.VITE_BASE_API as string

const api = axios.create({
  baseURL: baseApi,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default api
