<template>
    <div class="bg-[#E8F3FC] px-[12px] py-[8px] rounded-lg w-full">
        <div class="text-xs">
            {{ title }}
        </div>
        <div class="font-bold mt-1" v-if="showValue">
            {{ value }}
        </div>
        <div class="mt-1" v-else>
            <slot name="value" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps({
    title: String,
    value: String,
    showValue: Boolean
})

const { title, value, showValue } = toRefs(props)
</script>