<template>
  <svg width="108" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)" fill="#1C86E3">
      <path
        d="m35.365.806-7.323 5.8-13.213 10.456h-.004l-3.106-3.172c-.641-.655-2.1-.943-2.94-.604L1.665 16.04c-1.259.491-2.446 1.297-1 2.78L13.1 31.49c.64.655 1.755.704 2.346 0l10.187-12.14s-.01.01-.012.015l12.88-15.35a2.3 2.3 0 0 0-.161-3.03 2.203 2.203 0 0 0-2.976-.177V.806Z"
      />
      <path
        d="M14.421 11.512c3.125 0 5.656-2.578 5.656-5.762 0-3.183-2.533-5.75-5.656-5.75-3.123 0-5.644 2.579-5.644 5.75 0 3.172 2.53 5.762 5.644 5.762ZM47.44 6.798c-4.702 0-6.858 2.622-6.858 7.37v11.726h2.7c.836 0 1.513-.69 1.513-1.54v-5.857h.18c.309 1.364 1.387 2.99 3.802 2.99 3.21 0 5.471-2.572 5.471-7.24 0-4.67-2.415-7.45-6.808-7.45Zm-.076 10.806c-1.67 0-2.57-1.285-2.57-3.435 0-2.519 1.079-3.489 2.698-3.489 1.62 0 2.596 1.101 2.596 3.489 0 2.387-1.002 3.435-2.724 3.435ZM56.473 11.834v9.338h2.7c.836 0 1.513-.69 1.513-1.54v-7.065c0-1.18.513-1.575 1.54-1.575h1.49V7.11h-2.62c-2.877 0-4.623 1.31-4.623 4.722v.002ZM74.264 8.653v6.278c0 1.81-.641 2.675-2.235 2.675-1.593 0-2.182-.917-2.182-2.675V7.112h-4.212v7.816c0 4.197 2.08 6.558 6.37 6.558 4.007 0 6.474-1.993 6.474-6.558V7.112h-2.7c-.836 0-1.513.69-1.513 1.541h-.002ZM101.218 6.798c-4.032 0-6.884 2.491-6.884 7.344s2.596 7.345 6.884 7.345c4.032 0 6.782-2.492 6.782-7.345s-2.465-7.344-6.782-7.344Zm-.026 10.806c-1.567 0-2.671-1.05-2.671-3.462s1.052-3.462 2.671-3.462c1.771 0 2.645.97 2.645 3.462 0 2.361-1.078 3.462-2.645 3.462ZM89.074 8.056l-2.436 5.902-2.826-6.846h-4.675l7.501 14.766 7.501-14.766h-3.67c-.609 0-1.16.373-1.395.944Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h108v32H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts" setup></script>
