import moment, { type Moment } from 'moment'

export const days = {
  0: 'Dom',
  1: 'Seg',
  2: 'Ter',
  3: 'Qua',
  4: 'Qui',
  5: 'Sex',
  6: 'Sáb'
}

export const months = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro'
}

export const getBrazilianDate = (date: Moment) => {
  return moment(date).add(3, 'hours')
}

export const getUTCDateMoment = (date: Moment) => {
  return moment(date).subtract(3, 'hours')
}
