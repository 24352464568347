<template>
    <div class="calendar_layout calendar_style w-full">
        <div class="py-4 flex items-center justify-between gap-5 self-stretch border-[12px 12px 0 0] border-b-2">
            <div class="gap-2 flex items-center flex-[1 0 0] ">
                <CalendarIcon />
                <span class="flex flex-col justify-center flex-[1 0 0] text-sm text-center">{{ label }}</span>
            </div>
        </div>
        <div id="header" class="calendar_header_layout">
            <div id="month_selector" style="display: flex; gap: 1.25rem;">
                <ArrowIcon :isBack="true" @click="changeMonth(month - 1)" />
                <span style="user-select: none;">{{ monthsList[month] }}</span>
                <ArrowIcon :isBack="false" @click="changeMonth(month + 1)" />
            </div>
            <div id="year_selector" style="display: flex; gap: 1.25rem;">
                <ArrowIcon :isBack="true" @click="changeYear(year - 1)" />
                <span style="user-select: none;">{{ year }}</span>
                <ArrowIcon :isBack="false" @click="changeYear(year + 1)" />
            </div>
        </div>
        <table class="mb-5 w-full">
            <tr>
                <td v-for="weekday in weekList" :key="weekday" style="user-select: none; font-weight: 700;">{{ weekday }}
                </td>
            </tr>
            <tr v-for="w, wIndex in monthDays" :key="wIndex">
                <td v-for="day, dayIndex in w" :key="dayIndex" @click="returnDate">
                    <span v-if="day" class="day" :class="(selectedDay.getDate() === day.getDate()) && 'day_selected'"
                        @click="selectDay(day)">
                        {{ day?.getDate() }}
                    </span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import ArrowIcon from '../icons/ArrowIcon.vue';
import CalendarIcon from '../icons/CalendarIcon.vue';

const emit = defineEmits(['selectedDate'])

defineProps({
    label: {
        type: String,
        default: 'Data'
    }
})

const today = ref(new Date())

const month = ref(today.value.getMonth())

const year = ref(today.value.getFullYear())

const limitYear = ref(today.value.getFullYear() + 10)

const minYear = ref(today.value.getFullYear() - 120)

const yearsList = ref<string[]>()

const monthsList = ref<string[]>([
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
])

const weekList = ref<string[]>([
    'D', 'S', 'T', 'Q', 'Q', 'S', 'S'
])

const monthDays = ref<Date[][]>([[], [], [], [], [], []])

const selectedDay = ref<Date>(today.value)

function mountYearsList() {
    yearsList.value = []
    for (let i = minYear.value; i <= limitYear.value; i++) {
        yearsList.value?.push(i.toString())
    }
}

function changeYear(newValue: number) {
    if (newValue < minYear.value || newValue > limitYear.value) return
    year.value = newValue
}

function changeMonth(newValue: number) {
    if (newValue < 0) {
        // month.value = 11
        changeYear(year.value - 1)
        return
    }
    if (newValue > 11) {
        // month.value = 0
        changeYear(year.value + 1)
        return
    }
    month.value = newValue
}

function getDaysInMonth(month: number, year: number) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

function mountCalendar() {
    monthDays.value = [[], [], [], [], [], []]
    const actualMonthDays = getDaysInMonth(month.value, year.value)
    // TODO: set last and next month days
    // const lastMonthDays = getDaysInMonth(month.value - 1, year.value)
    // const nextMonthDays = getDaysInMonth(month.value + 1, year.value)

    let week = 0

    actualMonthDays.forEach((day) => {
        if (day.getDay() % 7 === 0) week++
        monthDays.value[week][day.getDay()] = day
    })
}

function selectDay(day: Date) {
    if (!day) return
    selectedDay.value = day
}

function returnDate() {
    const date = new Date(year.value, month.value, selectedDay.value.getDate())
    emit('selectedDate', date)
}

watchEffect(() => {
    if (!isNaN(month.value) && year.value) {
        mountYearsList()
        mountCalendar()
    }
})


</script>

<style scoped>
.calendar_layout {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px;
}

.calendar_style {
    border-radius: 12px;
    border: 1px solid var(--preto-90, #E6E6E6);
    background: var(--preto-100, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(27, 36, 44, 0.12);
}

.calendar_header_layout {
    display: flex;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.arrow {
    margin: 0 0.5rem;
}

td {
    text-align: center;
}

.button_actions {
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
    align-self: stretch;
}

.button_action_layout {
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.button_action_style {
    color: var(--azul-50, #1C86E3);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.1px;
    cursor: pointer;
    user-select: none;
}

.day {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6.25rem;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
}

.day_selected {
    background: var(--azul-50, #1C86E3);
    color: #FFF;
}

.day:hover {
    background: #E6E6E6;
    color: var(--azul-50, #1C86E3);
}
</style>