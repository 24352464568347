<template>
    <div class="flex items-center pt-[40px] flex-col h-full bg-[#EAF2F6]">
        <div class="w-[404px] px-[24px] py-[20px] bg-white rounded-xl">
            <div class="flex justify-center">
                <LogoPruvo />
            </div>
            <h1 class="mt-[24px] text-2xl text-black">
                {{ title }}
            </h1>
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import LogoPruvo from '../icons/LogoPruvo.vue';

const props = defineProps({
    title: String
})

const { title } = toRefs(props)
</script>