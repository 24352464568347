import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getUserData } from './user.service'
import { db } from '@/config/firebase'
import type LogGravityEnum from '@/enums/log-gravity.enum'

export default class LogService {
  applicationId: string

  constructor(applicationId: string) {
    this.applicationId = applicationId
  }

  public async log(action: string, gravity: LogGravityEnum): Promise<void> {
    const user = await getUserData()
    const ref = collection(db, 'users', user.id, 'applications', this.applicationId, 'logs')
    addDoc(ref, {
      action,
      gravity,
      registered_at: serverTimestamp()
    })
  }
}
