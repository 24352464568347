import { ref, computed } from 'vue'
import { useDisplay } from 'vuetify'
import StorageService from '@/services/storage.service'
import PdfService from '@/services/pdf.service'
import { useLoadingStore } from '@/stores/loading'
import MaterialsList from '@/enums/materials-list.enum'

export function useMaterialDialog() {
  const storageService = new StorageService()
  const pdfService = new PdfService()
  const loading = useLoadingStore()

  const dialogVisible = ref(false)
  const pdfHtml = ref('')
  const pdfWithAccessibility = ref<string>('')
  const pdfWithoutAccessibility = ref<string>('')
  const isAccessibilityMode = ref(false)

  const { mobile, lgAndUp, mdAndUp, smAndUp } = useDisplay()

  const displayWidth = computed(() => {
    if (lgAndUp.value || mdAndUp.value) {
      return 1200
    } else if (smAndUp.value) {
      return 800
    } else if (mobile.value) {
      return 300
    } else {
      return 450
    }
  })
  
  const displayHeight = computed(() => {
    if (lgAndUp.value) {
      return 1900
    } else if (mdAndUp.value) {
      return 800
    } else if (smAndUp.value || mobile.value) {
      return 600
    } else {
      return 700
    }
  })

  const isAccessibility = computed(() => {
    return isAccessibilityMode.value ? pdfWithoutAccessibility.value : pdfWithAccessibility.value
  })

  const openMaterial = async (type: string, link: string, linkAccessibility: string) => {
    try {
      pdfWithAccessibility.value = linkAccessibility
      pdfWithoutAccessibility.value = link
      isAccessibilityMode.value = false

      if (type !== MaterialsList.PDF) {
        window.open(link, '_blank')
        return
      }

      const url = await storageService.getFileUrl(`materialsPdfs/${pdfWithoutAccessibility.value}`)
      await loadPdfHtml(url)
      dialogVisible.value = true
    } catch (e) {
      console.error(e)
    }
  }

  const materialChangeAccessibility = async () => {
    try {
      const url = await storageService.getFileUrl(`materialsPdfs/${isAccessibility.value}`)
      await loadPdfHtml(url)
      isAccessibilityMode.value = !isAccessibilityMode.value
    } catch (e) {
      console.error(e)
    }
  }

  const loadPdfHtml = async (url: string) => {
    try {
      loading.startLoading()
      pdfHtml.value = await pdfService.readPdf(url)
    } catch (error) {
      console.error(error)
    } finally {
      loading.stopLoading()
    }
  }

  return {
    dialogVisible,
    pdfHtml,
    displayWidth,
    displayHeight,
    openMaterial,
    materialChangeAccessibility,
    pdfWithAccessibility
  }
}
