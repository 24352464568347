<template>
    <v-dialog width="350" v-model="showModal">
        <template v-slot:default>
            <v-card elevation="0" class="py-3">
                <v-card-text class="flex justify-center flex-col items-center">
                    <component :is="chosenIcon" class="mb-3" />
                    <div class="div-font-warning-style mb-3">
                        Você saiu da avaliação.
                    </div>
                    <div class="div-font-info-style">
                        {{ modalDescription }}
                    </div>
                </v-card-text>

                <v-card-actions class="flex justify-center">
                    <button class="button-layout button-style transition-all hover:opacity-60" @click="closeModal">
                        <span class="button-font-style">{{ buttonText }}</span>
                    </button>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modal';
import SadStudentIcon from '@/components/icons/SadStudentIcon.vue';
import { computed } from 'vue';
import SurpriseStudentIcon from '../icons/SurpriseStudentIcon.vue';

const modalStore = useModalStore()
const showModal = computed(() => modalStore.showModal)
const modalDescription = computed(() => modalStore.description)
const chosenIcon = computed(() => icons[modalStore.icon])
const buttonText = computed(() => modalStore.buttonText)
const closeModal = () => modalStore.closeModal()

const icons = {
    1: SadStudentIcon,
    2: SurpriseStudentIcon
} as any
</script>

<style scoped>
.button-layout {
    display: flex;
    height: 36px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.button-style {
    border-radius: 20px;
    border: 2px solid var(--azul-50, #1C86E3);
    background: var(--azul-99, #FAFDFE);
}

.button-font-style {
    color: var(--azul-50, #1C86E3);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.div-font-warning-style {
    color: var(--vermelho-50, #F5260A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.div-font-info-style {
    color: var(--bw-30, #4D4D4D);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
</style>