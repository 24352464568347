<template>
    <v-card class="!py-[24px]">
        <div :class="{ 'flex justify-between': mobile }">
            <v-card-title :class="{
                'text-h5 ml-2': !mobile,
                'text-h5 ml-2 w-5/6': mobile
            }">
                {{ selectedApplication?.name }}
            </v-card-title>
            <v-icon class="flex-1 mt-2" v-if="mobile" @click="emit('closeDialog')">mdi-close</v-icon>
        </div>
        <v-card-text class="!py-0 mt-[12px]">
            <div class="flex justify-center flex-col">
                <div class="text-sm mb-[4px]">
                    Professor
                </div>
                <div>
                    <v-avatar :image="author?.avatar"></v-avatar>
                    <span class="ml-[12px] font-bold">{{ author?.name ?? author?.email
                    }}</span>
                </div>
            </div>
            <div class="flex justify-between my-[12px]">
                <BasicInfoCard :showValue="true" title="Nota máxima" :value="`${grade.toFixed(2)}`" />
                <BasicInfoCard class="mx-3" :showValue="true" title="Questões" :value="`${questionsCount}`" />
                <BasicInfoCard :showValue="true" title="Máx. tentativas" :value="displayAttempts()" />
            </div>
            <div>
                <BasicInfoCard :showValue="false" title="Período para realização">
                    <template #value>
                        <div class="font-bold">
                            Abertura: {{
                                getBrazilianDate(moment.unix(selectedApplication?.starts_at?.seconds))
                                    .format('DD/MM/YY - HH:mm')
                            }}h
                        </div>
                        <div class="font-bold">
                            Encerramento: {{ selectedApplication?.ends_at?.seconds ?
                                getBrazilianDate(moment.unix(selectedApplication?.ends_at?.seconds)).format('DD/MM/YY - HH:mm')
                                + 'h' : 'Sem data final'
                            }}
                        </div>
                        <div class="font-bold">
                            Duração máxima: {{ selectedApplication?.duration === 0 ? 'Ilimitado' : `${selectedApplication?.duration}min` }}
                        </div>
                    </template>
                </BasicInfoCard>
            </div>
            <div class="my-[12px]">
                <div class="font-bold text-center mb-2">
                    Atenção
                </div>
                <div>
                    <div class="text-xs text-center" v-if="selectedApplication?.access_limit > 0">
                        Iniciar essa prova consumirá 1 tentativa. Você terá mais {{ selectedApplication?.access_limit }}
                        tentativa(s) para concluir caso precise pausar.
                    </div>
                    <div class="text-xs text-center" v-else>
                        Iniciar essa prova consumirá 1 tentativa. Você terá tentativas ilimitadas para concluir.
                    </div>
                </div>
            </div>
            <div>
                <v-btn @click="emit('start', selectedApplication)" variant="outlined" color="blue"
                    class="text-capitalize w-full !border-2">
                    Iniciar
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import moment from 'moment';
import BasicInfoCard from '../card/BasicInfoCard.vue';
import { getActiveQuestions, calculateFormActiveQuestionsGrade } from '@/services/application.service';
import { onMounted, ref } from 'vue';
import { getBrazilianDate } from '@/utils/date';
import { useDisplay } from 'vuetify';
import { getUserByRef } from '@/services/user.service';

const props = defineProps({
    selectedApplication: Object
})

const { selectedApplication } = toRefs(props)

const { mobile } = useDisplay();

const emit = defineEmits(['start', 'closeDialog'])

const questionsCount = ref(0)
const grade = ref(0)
const author = ref({} as any)

onMounted(async () => {
    questionsCount.value = (await getActiveQuestions(selectedApplication?.value?.form_ref)).length
    grade.value = await calculateFormActiveQuestionsGrade(selectedApplication?.value?.form_ref)
    author.value = await getUserByRef(selectedApplication?.value?.author_ref?.path)
})

const displayAttempts = () => {
    return selectedApplication?.value?.access_limit === 0 ? 'Ilimitado' : selectedApplication?.value?.access_limit;
}
</script>