import { defineStore } from "pinia";

export const useTimerCounterStore = defineStore('timerCounter', {
    state: () => ({
        count: 0,
        timerId: null as any,
    }),
    actions: {
        startCounter() {
            this.stopCounter();
            this.timerId = setInterval(() => {
                this.count++;
            }, 1000);
        },
        stopCounter() {
            clearInterval(this.timerId);
        },
        resumeCounter(value: number) {
            this.count = value;
            this.startCounter();
        },
        resetCounter() {
            this.count = 0;
            this.stopCounter();
        }
    },
    getters: {
        getCounter(): number {
            return this.count;
        },
        getCounterInMilliseconds(): number {
            return this.count * 1000;
        }
    }
});