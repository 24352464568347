export enum QuestionType {
  OBJECTIVE = 'OBJECTIVE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MATRIX = 'MATRIX',
  SLIDER = 'SLIDER',
  ONLY_TEXT = 'ONLY_TEXT',
  OPEN_ENDED = 'OPEN_ENDED',
  CONNECT_THE_DOTS = 'CONNECT_THE_DOTS',
  TRUE_FALSE = 'TRUE_FALSE',
  FILL_IN_THE_BLANK = 'FILL_IN_THE_BLANK',
  SORTING = 'SORTING',
  ESSAY = 'ESSAY'
}
