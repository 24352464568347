<template>
  <div v-for="alternative of answer" :key="alternative.id" class="mb-5">
    <div class="flex items-center border-2 border-[#E8F3FC] rounded-xl">
      <div
        class="blue-border-right position-relative h-[72px] p-[16px] flex justify-center items-center font-bold order-wording"
      >
        {{ numberToLetter(alternative?.order - 1) }}
      </div>
      <div class="px-5 py-[16px]">
        <QuestionMathJax :text="alternative?.wording" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import collect from 'collect.js'
import { computed } from 'vue'
import QuestionMathJax from '../QuestionMathJax.vue'
import { numberToLetter } from '@/utils/helpers'

const props = defineProps(['question'])

const answer = computed(() => {
  return collect(props.question.alternatives).whereIn('id', props.question.answers).toArray() as any
})
</script>

<style scoped>
.blue-border-right::after {
  content: '';
  height: 55px;
  width: 2px;
  background: #e8f3fc;
  position: absolute;
  right: 0;
}
</style>
