import { detectIncognito } from 'detectincognitojs'

export default class Helpers {
  private timerId: any = null

  async debounce(callback: Function, delaySeconds: number) {
    clearTimeout(this.timerId)
    this.timerId = setTimeout(async () => {
      await callback()
    }, delaySeconds * 1000)
  }
}

export const isIncognito = async (callback: (isIncognito: boolean) => void) =>
  (await detectIncognito()).isPrivate ? callback(true) : callback(false)

export const numberToLetter = (index: number | string) => {
  return String.fromCharCode(65 + Number(index))
}
