<template>
    <div class="my-5" v-for="answer, index in answers" :key="answer.order">
        <div class="flex items-center justify-between border-2 border-[#1BE477] rounded-xl w-full" :class="[
            answer?.id === findByOrder(answer?.order as number)?.id && 'border-[#1BE477]',
            answer?.id !== findByOrder(answer?.order as number)?.id && 'border-[#F5260A]',
        ]">
            <div class="flex items-center">
                <div class="position-relative h-[72px] blue-border-right p-[16px] flex justify-center items-center font-bold"
                    :class="[
                        answer?.id === findByOrder(answer?.order as number)?.id && 'correct-border-right correct-order-wording',
                        answer?.id !== findByOrder(answer?.order as number)?.id && 'wrong-border-right wrong-order-wording',
                    ]">
                    {{ numberToLetter(index) }}
                </div>
                <div class="px-[20px] py-[16px]">
                    <QuestionMathJax :text="answer?.wording" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import QuestionMathJax from '@/components/question/QuestionMathJax.vue';
import collect from 'collect.js';
import { computed, inject, type Ref, } from 'vue';
import { numberToLetter } from '@/utils/helpers'

const props = defineProps(['question'])
const formQuestions = inject('formQuestions') as Ref

const answers = computed(() => {
    return collect(props.question.answers)
        .map((answer: any) => {
            return {
                order: answer.answer,
                wording: props.question.alternatives.find((alternative: any) => alternative.id === answer.alternative_id).wording,
                id: answer.alternative_id
            }
        })
        .sortBy('order')
        .toArray() as any
})

const getQuestion = () => {
    const question = collect(formQuestions.value)
        .where('id', '==', props.question.id)
        .first()
    return question
}

const findByOrder = (order: number): any => {
    if (!order) {
        return null
    }
    const question: any = getQuestion();
    return collect(question?.alternatives)
        .where('order', '==', order)
        .first()
}

</script>

<style scoped lang="scss">
@mixin border-right-fn($color) {
    content: "";
    height: 55px;
    width: 2px;
    background: $color;
    position: absolute;
    right: 0;
}

@mixin order-wording-fn($color) {
    background-color: $color;
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
}

.blue-border-right::after {
    @include border-right-fn(#E8F3FC);
}

.correct-border-right::after {
    @include border-right-fn(#1BE477);
}

.wrong-border-right::after {
    @include border-right-fn(#F5260A);
}

.correct-order-wording {
    @include order-wording-fn(#1BE477)
}

.wrong-order-wording {
    @include order-wording-fn(#F5260A)
}
</style>