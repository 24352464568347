import { auth, db } from '@/config/firebase'
import collect from 'collect.js'
import {
  collection,
  collectionGroup,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where
} from 'firebase/firestore'
import { getOrganizationsFromArrayOfRefs } from './organization.service'
import type { Organization } from '@/models/organization.model'
import LevelEnum from '@/enums/level.enum'

export const getUserOrganizations = async (): Promise<Organization[]> => {
  const user = await getUserData()
  const organizationsRef = await getUserOrganizationsRef(user)
  return await getOrganizationsFromArrayOfRefs(organizationsRef)
}

export const getUserData = async () => {
  const q = query(collection(db, 'users'), where('email', '==', auth.currentUser?.email), limit(1))
  const querySnapshot = await getDocs(q)
  return querySnapshot?.docs?.[0]
}

const getUserOrganizationsRef = async (user: any): Promise<Array<DocumentReference>> => {
  const q = query(
    collectionGroup(db, 'people'),
    where('user_id', '==', user?.id),
    where('level', '==', LevelEnum.STUDENT)
  )
  const querySnapshot = await getDocs(q)
  return collect(querySnapshot.docs.map((doc) => doc.data()))
    .pluck('organization_ref')
    .unique('id')
    .all() as DocumentReference[]
}

export const getUserByRef = async (ref: string) => {
  const docRef = doc(db, ref)
  const docSnap = await getDoc(docRef)
  return docSnap.data()
}

export const setActiveOrganizationOnUser = async (organizationId: string) => {
  const user = await getUserData()
  await updateDoc(user.ref, {
    active_organization: {
      id: organizationId,
      updated_at: serverTimestamp()
    }
  })
}

export const clearActiveOrganizationOnUser = async () => {
  const user = await getUserData()
  await updateDoc(user.ref, {
    active_organization: null
  })
}
