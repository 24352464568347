<template>
  <div>
    <template v-if="provider?.id === ProviderEnum.LT">
      <ListToAddMaterialComponent :materials="ltData" />
    </template>
  </div>
</template>
<script lang="ts" setup>
import ProviderEnum from '../../enums/provider.enum'
import { useLoadingStore } from '@/stores/loading'
import { onMounted, ref } from 'vue'
import ListToAddMaterialComponent from './ListToAddMaterialComponent.vue'
import { getProviderById } from '@/services/provider.service'

const loadingStore = useLoadingStore()

const provider = ref()

onMounted(async () => {
  try {
    loadingStore.startLoading()
    const getProvider = await getProviderById(ProviderEnum.LT)
    provider.value = getProvider
  } catch (e) {
    console.error(e)
  } finally {
    loadingStore.stopLoading()
  }
})

const ltData = ref([
  {
    curricularComponent: 'Língua Portuguesa e Matemática',
    title: 'JOGOS DE LÍNGUA PORTUGUESA E MATEMÁTICA',
    schoolYear: '',
    type: '',
    content: '',
    curricularUnit: 'JOGOS SABE BRASIL',
    link: 'https://sabebrasil.gameseditoralt.com.br/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Cordeis',
    schoolYear: '3º a 5º ano',
    type: 'Vídeo',
    content: 'Gênero textual cordel',
    curricularUnit: '',
    link: 'https://beta.zabran.app/videos/AsasDaImaginacao/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Asas da imaginação',
    schoolYear: '3º a 5º ano',
    type: 'Vídeo',
    content: 'Gênero textual cordel',
    curricularUnit: '',
    link: 'https://beta.zabran.app/videos/Cordeis/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Ditos populares',
    schoolYear: '1º a 5º ano',
    type: 'Vídeo',
    content: 'Gêneros orais da tradição popular',
    curricularUnit: '',
    link: 'https://beta.zabran.app/videos/DitosPopulares/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Doces letras',
    schoolYear: '1º ano',
    type: 'Slideshow interativo',
    content: 'Correspondência fonema-grafema',
    curricularUnit: '',
    link: 'https://beta.zabran.app/DocesLetras/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Essa língua não é minha',
    schoolYear: '9º ano',
    type: 'Slideshow interativo',
    content: 'Variação linguística',
    curricularUnit: '',
    link: 'https://beta.zabran.app/EssaLinguaNaoEMinha/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Eu falo brasileirês',
    schoolYear: '3º a 9º ano',
    type: 'Slideshow interativo',
    content: 'Variação linguística',
    curricularUnit: '',
    link: 'https://beta.zabran.app/EuFaloBrasileires/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Festa a fantasia',
    schoolYear: '7º ano',
    type: 'Slideshow interativo',
    content: 'Substantivos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/FestaAFantasia/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Isso é cultura!',
    schoolYear: '5º ano',
    type: 'Slideshow interativo',
    content: 'Compreensão em leitura',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IssoECultura/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Língua da web',
    schoolYear: '3º a 5º ano',
    type: 'Slideshow interativo',
    content: 'Produção e recepção de textos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/LinguaDaWeb/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Mensagem virtual',
    schoolYear: '2º ano',
    type: 'Slideshow interativo',
    content: 'Produção e recepção de textos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/MensagemVirtual/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Meus atributos',
    schoolYear: '7º ano',
    type: 'Slideshow interativo',
    content: 'Adjetivos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/MeusAtributos/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Pirata na rede',
    schoolYear: '3º a 5º ano',
    type: 'Slideshow interativo',
    content: 'Palavras homônimas',
    curricularUnit: '',
    link: 'https://beta.zabran.app/PirataNaRede/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Poesia do sertão',
    schoolYear: '3º a 5º ano',
    type: 'Slideshow interativo',
    content: 'Gênero textual cordel',
    curricularUnit: '',
    link: 'https://beta.zabran.app/PoesiaDoSertao/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Qual é o ponto?',
    schoolYear: '5º ano',
    type: 'Slideshow interativo',
    content: 'Pontuação',
    curricularUnit: '',
    link: 'https://beta.zabran.app/QualEOPonto/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Se eu pudesse voltar',
    schoolYear: '5º ano e 6º ano',
    type: 'Slideshow interativo',
    content: 'Tempo e modo verbal',
    curricularUnit: '',
    link: 'https://beta.zabran.app/SeEuPudesseVoltar/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Só os pro',
    schoolYear: '8º ano',
    type: 'Slideshow interativo',
    content: 'Artigos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/SoOsPro/'
  },
  {
    curricularComponent: 'Língua Portuguesa',
    title: 'Um conto, de ponto a ponto',
    schoolYear: '6º a 9º ano',
    type: 'Slideshow interativo',
    content: 'Pontuação',
    curricularUnit: '',
    link: 'https://beta.zabran.app/UmContoDePontoAPonto/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'A galinha ruiva na fazenda da multiplicação',
    schoolYear: '2º. 3º e 4º ano',
    type: 'Slideshow interativo',
    content: 'Multiplicação',
    curricularUnit: '',
    link: 'https://beta.zabran.app/GalinhaRuivaNaFazendaDaMultiplicacao/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'A notinha, por favor!',
    schoolYear: '3º ano',
    type: 'Slideshow interativo',
    content: 'Numeração decimal ',
    curricularUnit: '',
    link: 'https://beta.zabran.app/ANotinhaPorFavor/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'A troco de quê?',
    schoolYear: '3º e 4º ano',
    type: 'Slideshow interativo',
    content: 'Cálculos com números decimais ',
    curricularUnit: '',
    link: 'https://beta.zabran.app/ATrocoDeQue/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Compras na net',
    schoolYear: '3º e 4º ano',
    type: 'Slideshow interativo',
    content: 'Cálculos com números decimais ',
    curricularUnit: '',
    link: 'https://beta.zabran.app/ComprasNaNet/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Cronologia do dinheiro',
    schoolYear: '5º e 6º ano',
    type: 'Slideshow interativo',
    content: 'Sistema monetário',
    curricularUnit: '',
    link: 'https://beta.zabran.app/CronologiaDoDinheiro/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Economia ou armadilha?',
    schoolYear: '2º e 3º ano',
    type: 'Slideshow interativo',
    content: 'Cálculos com números decimais',
    curricularUnit: '',
    link: 'https://beta.zabran.app/EconomiaOuArmadilha/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Em quantas vezes?',
    schoolYear: '5º ano',
    type: 'Slideshow interativo',
    content: 'Cálculos com números decimais',
    curricularUnit: '',
    link: 'https://beta.zabran.app/EmQuantasVezes/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'História do dinheiro',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Sistema monetário',
    curricularUnit: '',
    link: 'https://beta.zabran.app/HistoriaDoDinheiro/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Hora de dormir',
    schoolYear: '2º e 3º ano',
    type: 'Slideshow interativo',
    content: 'Leitura de horas',
    curricularUnit: '',
    link: 'https://beta.zabran.app/HoraDeDormir/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Ih, faltou!',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Valor posicional e composição e decomposição de números',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IhFaltou/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Leve mais pagando menos',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Sistema monetário e cálculos com números decimais',
    curricularUnit: '',
    link: 'https://beta.zabran.app/LeveMaisPagandoMenos/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'O mágico dos sinais',
    schoolYear: '3º e 4º ano',
    type: 'Vídeo',
    content: 'Números e operações',
    curricularUnit: '',
    link: 'https://beta.zabran.app/videos/OMagicoDosSinais/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'O meu negócio',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Medidas de capacidade',
    curricularUnit: '',
    link: 'https://beta.zabran.app/OMeuNegocio/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Surpresa econômica',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Fração e cálculos com números decimais',
    curricularUnit: '',
    link: 'https://beta.zabran.app/SurpresaEconomica/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Tudo tem que pagar',
    schoolYear: '6º a 9º ano',
    type: 'Vídeo',
    content: 'Educação financeira',
    curricularUnit: '',
    link: 'https://beta.zabran.app/videos/TudoTemQuePagar/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Tutu em: as compras',
    schoolYear: '4º ano',
    type: 'Slideshow interativo',
    content: 'Situações-problema com cálculos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/TutuEmAsCompras/'
  },
  {
    curricularComponent: 'Matemática',
    title: 'Tutu em: conheça o Tutu',
    schoolYear: '3º e 4º ano',
    type: 'Slideshow interativo',
    content: 'Situações-problema com cálculos',
    curricularUnit: '',
    link: 'https://beta.zabran.app/TutuEmConhecaOTutu/'
  },
  {
    curricularComponent: 'Ciências Humanas',
    title: 'História do Brasil: Independência - Volume I',
    schoolYear: '6º a 9.º ano',
    type: 'Slideshow interativo',
    content: 'Gênero textual: história em quadrinhos / Independência do Brasil',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IndependenciaDoBrasilVolumeI/'
  },
  {
    curricularComponent: 'Ciências Humanas',
    title: 'História do Brasil: Independência - Volume II',
    schoolYear: '6º a 9.º ano',
    type: 'Slideshow interativo',
    content: 'Gênero textual: história em quadrinhos / Independência do Brasil',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IndependenciaDoBrasilVolumeII/'
  },
  {
    curricularComponent: 'Ciências Humanas',
    title: 'História do Brasil: Independência - Volume III',
    schoolYear: '6º a 9.º ano',
    type: 'Slideshow interativo',
    content: 'Gênero textual: história em quadrinhos / Independência do Brasil',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IndependenciaDoBrasilVolumeIII/'
  },
  {
    curricularComponent: 'Ciências Humanas',
    title: 'História do Brasil: Independência - Volume IV',
    schoolYear: '6º a 9.º ano',
    type: 'Slideshow interativo',
    content: 'Gênero textual: história em quadrinhos / Independência do Brasil',
    curricularUnit: '',
    link: 'https://beta.zabran.app/IndependenciaDoBrasilVolumeIV/'
  },

  {
    title: 'Partes do Corpo com Homem Aranha',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/549/959'
  },
  {
    title: 'Cognição e Organização ',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/780/929'
  },
  {
    title: 'Que palavra é essa? ',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/820/176'
  },
  {
    title: 'Animais domésticos e selvagens (Nomes, Letras iniciais e finais)',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/893/562 '
  },
  {
    title: 'Vogal Inicial',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/3401/850/57232'
  },
  {
    title: 'De quem é essa sombra?',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/934/367'
  },
  {
    title: 'Encontre a metade dos monstrinhos',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/954/654'
  },
  {
    title: 'Números de 1 a 10',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80704/995/776'
  },
  {
    title: 'Respeito',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80705/013/629'
  },
  {
    title: 'Que ação é essa?',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/80705/033/446'
  },
  {
    title: 'Formas geométricas',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/17769/392/7580'
  },
  {
    title: 'Abre a caixa - formas geométricas e cores',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/17253/020/731'
  },
  {
    title: 'Jogo da memória numerais - 1 a 6 - Vire as cartas',
    curricularUnit: 'SABE BRASIL',
    link: 'https://wordwall.net/play/18419/554/389'
  }
])
</script>
