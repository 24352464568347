import { auth } from '@/config/firebase'
import ImpersonateService from '@/services/impersonate.service'
import { useLoadingStore } from '@/stores/loading'
import { onMounted, ref } from 'vue'

export const useImpersonate = () => {
  const impersonateService = new ImpersonateService()

  const loading = useLoadingStore()

  const isImpersonating = ref(false)

  onMounted(async () => {
    try {
      await verifyIsUserIsImpersonate()
    } catch (error) {
      console.error(error)
    }
  })

  async function verifyIsUserIsImpersonate() {
    const idTokenResult = await auth?.currentUser?.getIdTokenResult()

    if (idTokenResult?.claims?.impersonate) {
      isImpersonating.value = true
    }
  }

  async function depersonateUser() {
    try {
      loading.startLoading()
      await impersonateService.depersonate()
    } finally {
      loading.stopLoading()
    }
  }

  return {
    isImpersonating,
    verifyIsUserIsImpersonate,
    depersonateUser
  }
}
