<template>
    <v-row>
        <v-col :class="{'overflow-x-auto': mobile}">
            <table class="mt-3 border-separate">
                <tr>
                    <th></th>
                    <th
                        class="text-center font-normal text-sm pb-4" v-for="column, columnIndex in columns"
                        :style="fontSm"
                        :key="columnIndex">
                        {{ column ? column.answer : `Resposta ${columnIndex}` }}
                    </th>
                </tr>
                <tr :class="rowIndex === 0 && 'row' || rowIndex === rows.length - 1 && 'last-row'"
                    v-for="row, rowIndex in rowsWithAnswer" :key="rowIndex">
                    <td class="pr-3 text-right" :style="fontSm">{{ row ? row.wording : `Pergunta ${rowIndex}` }}
                    </td>
                    <td :class="rowIndex % 2 === 0 ? 'bg-[#E8F3FC] row-answer' : ''"
                        class="mx-auto min-w-[120px] border-2 border-[#D2E7F9] pt-5"
                        v-for="(column, columnIndex) in columns" :key="columnIndex">
                        <v-radio-group :disabled="isPreview" v-model="row.answer" @change="selectAnswer(row, column.id)">
                            <v-radio class="flex justify-center" :key="columnIndex" :value="column.id">
                            </v-radio>
                        </v-radio-group>
                    </td>
                </tr>
            </table>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { onMounted, toRefs } from 'vue';
import { useFontZoomStore } from '@/stores/font'
import { useDisplay } from 'vuetify';

const fontSm = computed(() => useFontZoomStore().getFontSm)

const props = defineProps({
    rows: {
        type: Array<any>,
        required: true
    },
    columns: {
        type: Array<any>,
        required: true
    },
    answers: {
        type: Array<any>,
        required: true,
        default: () => []
    },
    isPreview: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { rows, columns, answers } = toRefs(props)

const rowsWithAnswer = ref([] as any)

const { mobile } = useDisplay();

onMounted(() => {
    rowsWithAnswer.value = rows.value.map((row: any) => {
        row.answer = answers.value.find((answer: any) => answer.alternative_id === row.id)?.answer
        return row
    })
})

const emit = defineEmits(['answer'])

const selectAnswer = (row: any, answer: string) => {
    row.answer = answer

    emit('answer', rows.value.map((row: any) => ({
        alternative_id: row.id,
        answer: row.answer
    })))
}
</script>

<style scoped>
.row .row-answer:nth-child(2) {
    border-top-left-radius: 20px;
}

.row .row-answer:last-child {
    border-top-right-radius: 20px;
}

.last-row :nth-of-type(2) {
    border-bottom-left-radius: 20px;
}

.last-row :last-of-type {
    border-bottom-right-radius: 20px;
}
</style>