<template>
  <v-toolbar height="96" color="white" :class="isImpersonating ? 'mt-14 rounded-b-xl px-[32px] mb-[20px]' : 'rounded-b-xl px-[32px] mb-[20px]'
    ">
    <v-toolbar-title>
      <img v-if="providerLogo" :src="providerLogo" alt="Logo da instituição" width="64" height="64" />
      <LogoPruvo v-else />
    </v-toolbar-title>
    <div class="flex gap-2">
      <div class="flex flex-col items-end" :class="{
    'w-full justify-end': mobile
  }">
        <span v-if="!mobile" class="font-medium text-sm">{{ user?.name }}</span>
        <span class="cursor-pointer text-blue text-xs" id="menu-avatar-activator">Mais</span>
      </div>
      <v-avatar size="48">
        <img v-if="!user?.avatar" src="/assets/default_user.png" alt="Imagem de perfil" height="48" width="48" />
        <img v-else :src="user?.avatar" alt="Imagem de perfil" height="48" width="48" />
      </v-avatar>
    </div>
    <v-menu activator="#menu-avatar-activator">
      <v-list class="rounded-xl">
        <v-list-item @click="router.push('/organizations')">
          <v-icon class="text-blue">mdi-rotate-3d-variant</v-icon> Trocar instituição
        </v-list-item>
        <v-list-item item-props @click="handleLogout">
          <v-icon class="text-red">mdi-logout</v-icon> Sair
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script lang="ts" setup>
import LogoPruvo from '../icons/LogoPruvo.vue'
import type { Organization } from '@/models/organization.model'
import { onMounted, ref } from 'vue'
import { getActiveOrganizationData } from '@/services/organization.service'
import { logout } from '@/services/auth.service'
import { useRouter } from 'vue-router'
import { getProviderById } from '@/services/provider.service'
import { useLoadingStore } from '@/stores/loading'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useAuth'
import { useImpersonate } from '@/composables/useImpersonate'

const router = useRouter()
const loadingStore = useLoadingStore()
const { user } = useAuth()
const { mobile } = useDisplay()
const { isImpersonating } = useImpersonate()

const organization = ref({} as Organization)
const providerLogo = ref<string>('')

onMounted(async () => {
  organization.value = await getActiveOrganizationData()
  await getProviderLogo()
})

const handleLogout = async () => {
  try {
    loadingStore.startLoading()
    await logout()
  } finally {
    loadingStore.stopLoading()
  }
}

const getProviderLogo = async () => {
  const providerData = await getProviderById(organization?.value?.provider_id)
  providerLogo.value = providerData?.logo
}
</script>

<style>
.v-list {
  border: 1px solid #1c86e3;
}

.v-list-item {
  border-bottom: 1px solid #1c86e3;
}

.v-list-item:last-child {
  border-bottom: 0;
}
</style>
