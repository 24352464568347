<template>
    <div class="layout">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0007 1.3335C13.0999 1.3335 10.2642 2.19368 7.85229 3.80527C5.44037 5.41687 3.56051 7.70749 2.45042 10.3875C1.34034 13.0675 1.04989 16.0164 1.61581 18.8615C2.18172 21.7065 3.57859 24.3199 5.62976 26.3711C7.68093 28.4222 10.2943 29.8191 13.1393 30.385C15.9844 30.9509 18.9334 30.6605 21.6133 29.5504C24.2933 28.4403 26.584 26.5604 28.1955 24.1485C29.8071 21.7366 30.6673 18.901 30.6673 16.0002C30.6627 12.1117 29.116 8.38387 26.3665 5.63433C23.617 2.88479 19.8891 1.33808 16.0007 1.3335ZM16.0007 28.0002C13.6273 28.0002 11.3072 27.2964 9.33381 25.9778C7.36042 24.6592 5.82235 22.7851 4.9141 20.5924C4.00585 18.3997 3.76821 15.9869 4.23123 13.6591C4.69426 11.3313 5.83715 9.19311 7.51538 7.51488C9.19361 5.83665 11.3318 4.69376 13.6596 4.23074C15.9873 3.76772 18.4001 4.00536 20.5929 4.91361C22.7856 5.82186 24.6597 7.35993 25.9783 9.33332C27.2969 11.3067 28.0007 13.6268 28.0007 16.0002C27.9968 19.1816 26.7313 22.2316 24.4817 24.4812C22.2321 26.7308 19.1821 27.9963 16.0007 28.0002ZM17.334 24.0002H14.6673V21.3335H17.334V24.0002ZM17.334 18.6668H14.6673V8.00016H17.334V18.6668Z" fill="#4A545E"/>
        </svg>
        <div class="text_layout">
            <span class="text_style">
                Você não está inserido em nenhuma instituição
            </span>
        </div>
    </div>
</template>
<style scoped>
.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.text_layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}
.text_style {
    color: var(--bw-20, #333);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
}
</style>