<template>
  <button
    @click="props.click"
    class="!bg-Azul-95 !border-Azul-99 !border-2 rounded-[20px] h-[10rem] flex flex-col w-full items-center justify-center cursor-pointer group"
  >
    <div class="bg-Azul-50 rounded-full p-2 mb-2 group-hover:scale-110 scale-transition duration-300">
      <component :is="component" fill="white" height="26" width="26"/>
    </div>
    <span class="text-B&W-30 text-[1rem] font-medium leading-6 tracking-[0.15px] line-clamp-1">
      {{ props.customText }}
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { LoadMoreStatusEnum } from '@/enums/load-more-status.enum'
import AddCircle from '@/components/icons/AddCircle.vue'
import MinusCircle from '@/components/icons/MinusCircle.vue'

const props = defineProps({
  click: {
    type: Function,
    required: false
  },
  customText: {
    type: String,
    default: 'Ver menos avaliações em aberto'
  },
  icon: {
    type: String
  }
})

const component = computed(() => {
  switch (props.icon) {
    case LoadMoreStatusEnum.MORE:
      return AddCircle;
    case LoadMoreStatusEnum.NO_MORE:
      return MinusCircle;
    default:
      return AddCircle;
  }
});
</script>