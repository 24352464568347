import * as jose from 'jose'

export default class JwtUtils {
  static isJwt(token: string): boolean {
    const parts = token.split('.');
    if (parts.length !== 3) {
      return false;
    }
    try {
      for (const part of parts) {
        const base64 = part.replace(/-/g, '+').replace(/_/g, '/');
        window.atob(base64);
      }
    } catch {
      return false;
    }

    return true;
  }

  static decodeJwt(token: string): any {
    return jose.decodeJwt(token) as any;
  }
}