<template>
  <v-app-bar v-if="isImpersonating" color="primary" class="md:px-8 md:py-1">
    <template #title>
      <span class="text-base">Você está personificando&nbsp;</span>
      <span class="text-base font-semibold">"{{ auth.currentUser?.email }}"</span>
    </template>
    <template #append>
      <v-btn color="white" variant="flat" class="text-none" :rounded="false" @click="depersonateUser">
        <template #prepend>
          <v-icon>mdi-logout</v-icon>
        </template>
        <template #default v-if="!mobile">Despersonificar</template>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import { auth } from '@/config/firebase'
import { useDisplay } from 'vuetify'
import { useImpersonate } from '@/composables/useImpersonate'

const { mobile } = useDisplay()
const { isImpersonating, depersonateUser } = useImpersonate()
</script>
