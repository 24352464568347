<template>
  <div>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="auto">
          <v-button icon class="small-btn hover-effect" @click="goBack">
            <v-icon style="color: #1C86E3; font-size: 32px;">mdi-arrow-left</v-icon>
          </v-button>
        </v-col>

        <v-col>
          <v-tabs v-model="tab">
            <v-tab value="support-material">Livros</v-tab>
            <v-tab value="support-toaddmaterial">Materiais adicionais</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-window v-model="tab">
            <v-window-item value="support-material">
              <ListSupportMaterial />
            </v-window-item>
            <v-window-item value="support-toaddmaterial">
              <ListToAddMaterial />
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import ListSupportMaterial from '@/components/support-material/ListSupportMaterial.vue'
import ListToAddMaterial from '@/components/support-material/ListToAddMaterial.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'


const tab = ref('support-material')
const router = useRouter()

const goBack = () => {
  router.push('/dashboard')
}
</script>

<style scoped>
.small-btn {
  min-width: 1rem;    
  min-height: 1rem;   
  padding: 0.5rem;       
  font-size: 1rem;       
  border-radius: 50%;
  display: flex;         
  align-items: center;   
  justify-content: center; 
}

.hover-effect {
  transition: transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

.hover-effect:hover {
 
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
</style>
