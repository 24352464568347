import api from '@/config/axios'
import type { AxiosRequestConfig } from 'axios'
import { getIdToken, type User } from 'firebase/auth'
import { auth } from '@/config/firebase'

export default class ImpersonateService {
  public async depersonate() {
    const token = await getIdToken(auth?.currentUser as User, true)
    const response = await api.post('/depersonate', undefined, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    } as AxiosRequestConfig)
    this.redirectToProject(import.meta.env.VITE_TEACHER_URL, response.data.data)
  }

  private redirectToProject(urlString: string, token: string) {
    const url = new URL(urlString)
    url.searchParams.set('customToken', token)
    window.location.href = url.toString()
  }
}
