<template>
  <v-btn
    v-if="
      application?.status === ApplicationStatus.NOT_STARTED.toString() ||
      userApplication?.status === ApplicationStatus.NOT_STARTED.toString()
    "
    class="!bg-[#A4CFF4] w-full !border-2 !capitalize"
    color="blue"
    elevation="0"
    rounded="xl"
    variant="flat"
    @click="start(application)"
    >{{application?.is_google_forms ? 'Abrir Google Formulário' : 'Iniciar'}}
  </v-btn>
  <v-btn
    v-else-if="application?.status === ApplicationStatus.IN_PROGRESS.toString()"
    class="!bg-[#A4CFF4] w-full !border-2 !capitalize"
    color="blue"
    elevation="0"
    rounded="xl"
    variant="flat"
    @click="resume(application)"
    >Retomar
  </v-btn>
  <v-btn
    v-else-if="userApplication?.status === ApplicationStatus.PUBLISHED.toString()"
    :disabled="userApplication?.status !== ApplicationStatus.PUBLISHED.toString()"
    class="w-full !border-2 !capitalize"
    color="blue"
    elevation="0"
    rounded="xl"
    variant="outlined"
    @click="goToFeedback(application.id)"
  >
    Gabarito
  </v-btn>
</template>

<script lang="ts" setup>
import { db } from '@/config/firebase'
import { ApplicationStatus } from '@/enums/application-status.enum'
import type { Application } from '@/models/application.model'
import { doc } from 'firebase/firestore'
import { computed, inject, type PropType } from 'vue'
import { useDocument } from 'vuefire'

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true
  }
})

const start = inject('start') as Function
const resume = inject('resume') as Function
const goToFeedback = inject('goToFeedback') as Function

const userApplicationSource = computed(() => {
  if (!props.application?.id || !props.application?.user_id) return
  return doc(db, 'users', props.application.user_id, 'applications', props.application.id)
})

const userApplication = useDocument(userApplicationSource)
</script>
