import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  memoryLocalCache,
  memoryLruGarbageCollector
} from 'firebase/firestore'

const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)

export const firebaseApp = initializeApp(firebaseConfig)
export const setup = () => {
  return firebaseApp
}
export const auth = getAuth(firebaseApp)
export const db = initializeFirestore(firebaseApp, {
  localCache: memoryLocalCache({
    garbageCollector: memoryLruGarbageCollector({
      cacheSizeBytes: CACHE_SIZE_UNLIMITED
    })
  }),
  ignoreUndefinedProperties: true
})
