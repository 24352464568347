import type { DirectiveBinding } from "vue";

export const vLimitCharacters = (el: HTMLInputElement | HTMLTextAreaElement, binding: DirectiveBinding<string>) => {
    const maxChars = Number(binding.value);
  
    el.addEventListener('input', function (event) {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        const value = target.value;
  
        if (value.length > maxChars) {
            target.value = value.slice(0, maxChars);
            target.dispatchEvent(new Event('input'));
        }
    })
  }