<template>
    <div class="mb-[20px]" v-for="alternative, index of mergedAlternatives" :key="alternative.id">
        <div class="flex items-center border-2 rounded-xl" :class="[
            alternative.is_correct && question.answers.includes(alternative.id) && 'border-[#1BE477]',
            !alternative.is_correct && question.answers.includes(alternative.id) && 'border-[#F5260A]',
            alternative.is_correct && !question.answers.includes(alternative.id) && 'border-[#1BE477]',
            !alternative.is_correct && !question.answers.includes(alternative.id) && 'border-[#E8F3FC]',
        ]">
            <div class="position-relative h-[72px] p-[16px] flex justify-center items-center font-bold" :class="[
                alternative.is_correct && question.answers.includes(alternative.id) && 'correct-border-right correct-order-wording',
                !alternative.is_correct && question.answers.includes(alternative.id) && 'wrong-border-right wrong-order-wording',
                alternative.is_correct && !question.answers.includes(alternative.id) && 'correct-border-right',
                !alternative.is_correct && !question.answers.includes(alternative.id) && 'blue-border-right',
            ]">
                <span class="text-base">
                    {{ numberToLetter(index) }}
                </span>
            </div>
            <div class="px-[20px] py-[16px]">
                <QuestionMathJax class="text-base" :text="alternative?.wording" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import { inject, watchEffect, ref } from 'vue';
import collect from 'collect.js';
import QuestionMathJax from '@/components/question/QuestionMathJax.vue';
import { numberToLetter } from '@/utils/helpers'

const props = defineProps(['question'])
const formQuestions = inject('formQuestions') as Ref

const mergedAlternatives = ref()

const getQuestion = () => {
    const question = collect(formQuestions.value)
        .where('id', '==', props.question.id)
        .first()
    return question
}

const mergeAlternatives = () => {
    const question: any = getQuestion();
    const alternatives = collect(props?.question?.alternatives)
        .map((alternative: any) => {
            const formAlternative: any = collect(question?.alternatives)
                .where('id', '==', alternative.id)
                .first()

            return {
                ...alternative,
                ...formAlternative,
            }
        })
    mergedAlternatives.value = alternatives
}

watchEffect(() => {
    mergeAlternatives()
})


</script>

<style scoped lang="scss">
@mixin border-right-fn($color) {
    content: "";
    height: 55px;
    width: 2px;
    background: $color;
    position: absolute;
    right: 0;
}

@mixin order-wording-fn($color) {
    background-color: $color;
    border-start-start-radius: 1rem;
    border-end-start-radius: 1rem;
}

.blue-border-right::after {
    @include border-right-fn(#E8F3FC);
}

.correct-border-right::after {
    @include border-right-fn(#1BE477);
}

.wrong-border-right::after {
    @include border-right-fn(#F5260A);
}

.correct-order-wording {
    @include order-wording-fn(#1BE477)
}

.wrong-order-wording {
    @include order-wording-fn(#F5260A)
}
</style>