<template>
  <MatrixComponent :answers="answers" :columns="columns" :rows="rows" isPreview />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import MatrixComponent from '../MatrixComponent.vue'

const props = defineProps(['question'])
const rows = computed(() => props.question.rows)
const columns = computed(() => props.question.columns)
const answers = computed(() => props.question.answers)
</script>
