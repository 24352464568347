<template>
    <v-row>
        <v-col :class="{'overflow-x-auto': mobile}">
            <table class="mt-3 border-separate">
                <tr>
                    <th></th>
                    <th class="text-center font-normal text-sm pb-4" v-for="column, columnIndex in columns"
                        :key="columnIndex">
                        {{ column ? column.answer : `Resposta ${columnIndex}` }}
                    </th>
                </tr>
                <tr :class="rowIndex === 0 && 'row' || rowIndex === rows.length - 1 && 'last-row'"
                    v-for="row, rowIndex in rowsWithAnswer" :key="rowIndex">
                    <td class="pr-3 text-right text-sm">{{ row ? row.wording : `Pergunta ${rowIndex}` }}
                    </td>
                    <td v-for="(column, columnIndex) in columns" :key="columnIndex"
                        class="mx-auto min-w-[120px] border-2 border-[#D2E7F9] pt-5" :class="[
                            rowIndex % 2 === 0 ? 'bg-[#E8F3FC] row-answer' : '',
                        ]">
                        <v-radio-group readonly v-model="row.answer"
                            :color="getRightAnswer(row.correct_index)?.id === row.answer ? '#1BE477' : '#F5260A'">
                            <v-radio class="flex justify-center" false-icon="mdi-square-rounded"
                                true-icon="mdi-square-rounded"
                                :color="getRightAnswer(row.correct_index)?.id === row.answer ? '#1BE477' : '#F5260A'"
                                :key="columnIndex" :value="column.id" />
                        </v-radio-group>
                        <br>
                    </td>
                </tr>
            </table>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import collect from 'collect.js';
import { computed, ref, watchEffect } from 'vue';
import { useDisplay } from 'vuetify';

const props = defineProps(['question'])
const rows = computed(() => props.question.rows)
const columns = computed(() => props.question.columns)
const answers = computed(() => props.question.answers)
const rowsWithAnswer = ref<any[]>([] as any)
const { mobile } = useDisplay();

watchEffect(() => {
    rowsWithAnswer.value = rows.value.map((row: any) => {
        row.answer = answers.value?.find((answer: any) => answer.alternative_id === row.id)?.answer
        return row
    })
})

const getRightAnswer = (rightIndex: number) => {
    const rightAnswer = collect(columns.value)
        .where('index', '==', rightIndex)
        .first() as any

    return rightAnswer
}


</script>

<style scoped>
.row .row-answer:nth-child(2) {
    border-top-left-radius: 20px;
}

.row .row-answer:last-child {
    border-top-right-radius: 20px;
}

.last-row :nth-of-type(2) {
    border-bottom-left-radius: 20px;
}

.last-row :last-of-type {
    border-bottom-right-radius: 20px;
}
</style>