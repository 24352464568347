<template>
    <div class="mb-[20px]" v-for="alternative, index of alternatives" :key="alternative.id">
        <div class="flex items-center justify-between border-2 border-[#E8F3FC] rounded-xl">
            <div class="flex items-center">
                <div class="position-relative h-[72px] blue-border-right p-[16px] flex justify-center items-center font-bold"
                    :style="fontBase">
                    {{ numberToLetter(index) }}
                </div>
                <div class="px-[20px] py-[16px]">
                    <QuestionMathJax :text="alternative?.wording" :style="fontBase" />
                </div>
            </div>
            <div class="pr-[20px] py-[16px] flex items-center">
                <div :class="{ 'flex': !mobile }">
                    <div :class="answersSelected
                        .filter((answerSelected: any) => answerSelected.alternative_id === alternative.id)
                        .length > 0 && answersSelected.find((answer: any) => answer.alternative_id === alternative.id)?.answer ? 'border-green-500' : 'hover:border-green-100'"
                        class="cursor-pointer flex justify-center items-center ml-[7px] mb-1 w-[48px] h-[48px] rounded-full border-2 p-[12px]"
                        @click="selectAnswer(alternative.id, true)">
                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.292786 5.79317C0.480314 5.6057 0.734622 5.50039 0.999786 5.50039C1.26495 5.50039 1.51926 5.6057 1.70679 5.79317L5.98179 10.0682L15.2748 0.311174C15.4575 0.118892 15.7092 0.00707569 15.9743 0.000324382C16.2395 -0.00642692 16.4965 0.0924394 16.6888 0.275174C16.8811 0.457908 16.9929 0.709541 16.9996 0.974717C17.0064 1.23989 16.9075 1.49689 16.7248 1.68917L6.72479 12.1892C6.63274 12.286 6.52223 12.3634 6.39977 12.4168C6.2773 12.4703 6.14538 12.4986 6.01179 12.5002H5.99979C5.73459 12.5001 5.48028 12.3947 5.29279 12.2072L0.292786 7.20717C0.105315 7.01965 0 6.76534 0 6.50017C0 6.23501 0.105315 5.9807 0.292786 5.79317Z"
                                fill="#333333" />
                        </svg>
                    </div>
                    <div :class="answersSelected
                        .filter((answerSelected: any) => answerSelected.alternative_id === alternative.id)
                        .length > 0 && !answersSelected.find((answer: any) => answer.alternative_id === alternative.id)?.answer ? 'border-red-500' : 'hover:border-red-100'"
                        class="ml-[8px] flex justify-center items-center w-[48px] h-[48px] cursor-pointer rounded-full border-2 p-[12px]"
                        @click="selectAnswer(alternative.id, false)">
                        <svg width="16" height="16" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.292919 15.2931L6.58592 9.00008L0.292919 2.70708C0.110761 2.51848 0.00996641 2.26588 0.0122448 2.00368C0.0145233 1.74148 0.119692 1.49067 0.305101 1.30526C0.490509 1.11985 0.741321 1.01469 1.00352 1.01241C1.26571 1.01013 1.51832 1.11092 1.70692 1.29308L7.99992 7.58608L14.2929 1.29308C14.3852 1.19757 14.4955 1.12139 14.6175 1.06898C14.7395 1.01657 14.8707 0.988985 15.0035 0.987831C15.1363 0.986677 15.268 1.01198 15.3909 1.06226C15.5138 1.11254 15.6254 1.18679 15.7193 1.28069C15.8132 1.37458 15.8875 1.48623 15.9377 1.60913C15.988 1.73202 16.0133 1.8637 16.0122 1.99648C16.011 2.12926 15.9834 2.26048 15.931 2.38249C15.8786 2.50449 15.8024 2.61483 15.7069 2.70708L9.41392 9.00008L15.7069 15.2931C15.8024 15.3853 15.8786 15.4957 15.931 15.6177C15.9834 15.7397 16.011 15.8709 16.0122 16.0037C16.0133 16.1365 15.988 16.2681 15.9377 16.391C15.8875 16.5139 15.8132 16.6256 15.7193 16.7195C15.6254 16.8134 15.5138 16.8876 15.3909 16.9379C15.268 16.9882 15.1363 17.0135 15.0035 17.0123C14.8707 17.0112 14.7395 16.9836 14.6175 16.9312C14.4955 16.8788 14.3852 16.8026 14.2929 16.7071L7.99992 10.4141L1.70692 16.7071C1.51832 16.8892 1.26571 16.99 1.00352 16.9878C0.741321 16.9855 0.490509 16.8803 0.305101 16.6949C0.119692 16.5095 0.0145233 16.2587 0.0122448 15.9965C0.00996641 15.7343 0.110761 15.4817 0.292919 15.2931Z"
                                fill="#333333" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import collect from 'collect.js';
import { computed, ref } from 'vue';
import { toRefs } from 'vue';
import { useFontZoomStore } from '@/stores/font'
import { useDisplay } from 'vuetify';
import QuestionMathJax from './QuestionMathJax.vue';
import { numberToLetter } from '@/utils/helpers'

const fontBase = computed(() => useFontZoomStore().getFontBase)

const props = defineProps({
    alternatives: {
        type: Array<any>,
        required: true
    },
    answers: {
        type: Array<any>,
        required: true,
        default: () => []
    }
})

const { alternatives, answers } = toRefs(props)

const { mobile } = useDisplay();

const emit = defineEmits(['answer'])

const answersSelected = ref(answers.value)

const selectAnswer = (alternativeId: any, answer: boolean) => {
    const alternativeExistsInAnswer = collect(answersSelected.value).contains('alternative_id', alternativeId)

    if (!alternativeExistsInAnswer) {
        answersSelected.value.push({
            alternative_id: alternativeId,
            answer
        })
    } else {
        answersSelected.value.map((alternative: any) => {
            if (alternative.alternative_id === alternativeId) {
                alternative.answer = answer
            }
            return alternative
        })
    }

    emit('answer', answersSelected.value)
}
</script>

<style scoped>
.blue-border-right::after {
    content: "";
    height: 55px;
    width: 2px;
    background: #E8F3FC;
    position: absolute;
    right: 0;
}
</style>