<template>
  <TitleBorderContentCard :title="title" icon="mdi-file-document-multiple-outline">
    <div class="flex flex-col">
      <div v-for="(material, index) in materials" :key="index">
        <v-card
          class="item"
          elevation="0"
          @click="openMaterial(material.type, material.link, material.linkAccessibily || '')"
        >
          <div class="flex p-5 space-x-5">
            <img :src="material.img" class="rounded max-h-[6rem] max-w-[4rem]" />
            <div class="flex flex-col flex-grow">
              <p class="text-blue text-sm font-bold my-1">{{ material.curricularUnit }}</p>
              <span
                class="text-[#333] text-base font-medium sm:overflow-hidden sm:text-ellipsis sm:whitespace-nowrap"
              >
                {{ material.title }}
              </span>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </TitleBorderContentCard>

  <BaseDialogComponent
    v-if="pdfHtml && dialogVisible"
    v-model="dialogVisible"
    :accessibilityButton="!!pdfWithAccessibility"
    :height="displayHeight"
    :save-button="false"
    :width="displayWidth"
    class="!p-0"
    title="Material de apoio"
    @accessibilityDialog="materialChangeAccessibility"
    @close-dialog="dialogVisible = false"
  >
    <div class="flex justify-center h-[450px] sm:h-[600px] md:h-[900px]">
      <iframe ref="frame" :srcdoc="pdfHtml" class="w-full h-full"></iframe>
    </div>
  </BaseDialogComponent>
</template>

<script lang="ts" setup>
import TitleBorderContentCard from '../card/TitleBorderContentCard.vue'
import BaseDialogComponent from '@/components/dialog/BaseDialogComponent.vue'
import { useMaterialDialog } from '@/composables/useMaterialDialog'

const {
  dialogVisible,
  pdfHtml,
  displayWidth,
  displayHeight,
  openMaterial,
  materialChangeAccessibility,
  pdfWithAccessibility
} = useMaterialDialog()

defineProps<{
  materials: Array<{
    curricularUnit: string
    title: string
    link: string
    linkAccessibily?: string
    img: string
    type: string
  }>
  title: string
}>()
</script>

<style scoped>
.item {
  border-radius: 8px;
  border: 1px solid var(--preto-90, #e6e6e6);
  background: var(--preto-99, #fcfcfc);
  box-shadow: 0 1px 2px rgba(27, 36, 44, 0.12);
}
</style>
