<template>
    <div class="w-[50%] pt-2">
        <v-slider
            :model-value="answer"
            color="blue"
            show-ticks="always"
            thumb-label="always"
            tick-size="4"
            readonly
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps(['question'])

const answer = computed(() => {
    return props.question.answers[0]
})

</script>
