<template>
  <div v-for="answer in answers" :key="answer.order" class="my-5">
    <div class="flex items-center justify-between border-2 border-[#E8F3FC] rounded-xl w-full">
      <div class="flex items-center">
        <div
          class="position-relative h-[72px] blue-border-right p-[16px] flex justify-center items-center font-bold"
        >
          {{ numberToLetter(answer?.order - 1) }}
        </div>
        <div class="px-[20px] py-[16px]">
          <QuestionMathJax :text="answer?.wording" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import collect from 'collect.js'
import QuestionMathJax from '../QuestionMathJax.vue'
import { numberToLetter } from '@/utils/helpers'

const props = defineProps(['question'])

const answers = computed(() => {
  return collect(props.question.answers)
    .map((answer: any) => {
      return {
        order: answer.answer,
        wording: props.question.alternatives.find(
          (alternative: any) => alternative.id === answer.alternative_id
        ).wording
      }
    })
    .sortBy('order')
    .toArray() as any
})
</script>

<style scoped>
.blue-border-right::after {
  content: '';
  height: 55px;
  width: 2px;
  background: #e8f3fc;
  position: absolute;
  right: 0;
}
</style>
