<template>
  <AuthCardComponent title="Organização">
    <v-list class="!bg-transparent !border-transparent !p-0">
      <template v-if="loading">
        <div class="flex items-center justify-center my-3">
          <v-progress-circular color="primary" indeterminate size="20"></v-progress-circular>
        </div>
      </template>
      <HasNoOrganizations v-else-if="!userOrganizations?.length" />
      <v-list-item
        v-for="organization in userOrganizations"
        v-else
        :key="organization.id"
        class="border cursor-pointer rounded-xl mt-[24px]"
        style="border-color: #a4cff4 !important"
        @click="setOrganization(organization.id)"
      >
        <template #prepend>
          <v-avatar :image="organization.logo" height="48" width="48" />
        </template>
        <v-list-item-title class="m-[12px]" data-cy="organization-name">
          <h2 class="font-medium text-sm">{{ organization.name.trade }}</h2>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </AuthCardComponent>
</template>

<script lang="ts" setup>
import type { Organization } from '@/models/organization.model'
import { getUserOrganizations } from '@/services/user.service'
import { onMounted, ref } from 'vue'
import { setActiveOrganization } from '@/services/organization.service'
import AuthCardComponent from '@/components/auth/AuthCardComponent.vue'
import { generateToken } from '@/services/meilisearch.service'
import { useLoadingStore } from '@/stores/loading'
import HasNoOrganizations from '@/components/HasNoOrganizations.vue'

const userOrganizations = ref([] as Organization[])

const loadingStore = useLoadingStore()

const loading = ref(false)

onMounted(async () => {
  try {
    loading.value = true
    userOrganizations.value = (await getUserOrganizations()).filter(
      (organization) => organization?.id
    )
  } finally {
    loading.value = false
  }
})

const setOrganization = async (organizationId: string) => {
  try {
    loadingStore.startLoading()
    await setActiveOrganization(organizationId)
    await generateToken()
    location.href = '/'
  } finally {
    loadingStore.stopLoading()
  }
}
</script>
