import { defineStore } from 'pinia'

export const useConfettiStore = defineStore('confetti', {
  state: () => ({
    confetti: false
  }),
  actions: {
    startConfetti() {
      this.confetti = true
    },
    stopConfetti() {
      this.confetti = false
    }
    
  }
})
