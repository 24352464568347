import { auth } from '@/config/firebase'
import { getPersonDataOnActiveOrganization } from '@/services/auth.service'
import { getUserData } from '@/services/user.service'
import { reactive } from 'vue'

export function useAuth() {
  const user = reactive({
    name: '',
    avatar: ''
  })
  getUserData().then((u) =>
    getPersonDataOnActiveOrganization().then((p) => {
      user.name =
        p?.name ||
        u?.data?.()?.name ||
        auth?.currentUser?.displayName ||
        p?.email?.split?.('@')?.[0]
      user.avatar = p?.logo || u?.data?.()?.avatar || auth?.currentUser?.photoURL || ''
    })
  )
  return {
    user
  }
}
